<template>
    <div>
        <v-dialog
            v-model="dialog"
            max-width="500px"
            :disabled="loading"
            persistent
        >
            <v-card>
                <v-card-title>
                    <span class="headline">{{
                        form.id ? "Edit Payout" : "Create Payout"
                    }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <!-- <v-row> -->
                        <v-form ref="payoutFormRef">
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <v-combobox
                                        label="Country Name"
                                        variant="outlined"
                                        v-model="form.country_ids"
                                        :items="countries"
                                        item-value="id"
                                        :return-object="false"
                                        item-title="name"
                                        :rules="[
                                            (v) => !!v || 'Country is required',
                                        ]"
                                    >
                                    </v-combobox>
                                </div>
                                <div class="col-md-6">
                                    <!-- 34.00 -->
                                    <v-text-field
                                        :required="true"
                                        v-model="form.amount"
                                        label="Amount"
                                        variant="outlined"
                                        type="number"
                                        :rules="[
                                            (v) => !!v || 'Amount is required',
                                            (v) =>
                                                /^\d+(\.\d{1,2})?$/.test(v) ||
                                                /^\d+$/.test(v) ||
                                                'Amount must be a number',
                                            (v) =>
                                                v > 0 ||
                                                'Amount must be greater than 0',
                                        ]"
                                    ></v-text-field>
                                </div>
                            </div>
                        </v-form>
                        <!-- </v-row> -->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn
                        @click="submitForm"
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                    >
                        submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- {{ payoutData }} -->
        <div class="statusDiv mb-4">
            <!-- <v-btn @click="addRecord">Create</v-btn> -->
            <StandardBtn type="create" @click="addRecord" />
        </div>
        <Vue3Datatable
            @pageChange="handlePageChange"
            @pageSizeChange="handlePageSizeChange"
            :paginationInfo="
                'Showing {0} to {1} of {2} entries' +
                ` | Page ${page} of ${Math.ceil(payoutData.length / pageSize)}`
            "
            :stickyHeader="true"
            :columns="cols"
            :rows="payoutData"
            :sortable="true"
            :loading="loading"
            skin="bh-table-bordered bh-table-responsive"
        >
            <template #country_ids="{ value }">
                <CountryInfo :id="value.country_ids" />
            </template>
            <template #amount="{ value }">
                {{ $formatCurrency(value.amount) }}
            </template>
            <!-- <template #advertiser_id="{ value }">
                <span>{{ getAdvertisers(value.advertiser_id) }}</span>
            </template> -->

            <template #actions="{ value }">
                <div class="d-flex gap-2">
                    <!-- <button
                        type="button"
                        class="btn btn-outline-success"
                        @click="editRecord(value)"
                    >
                        <i class="bi bi-check-circle"></i>
                        Edit
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-danger"
                        @click="deletePayout(value.id)"
                    >
                        <i class="bi bi-x-circle"></i>
                        Delete
                    </button> -->
                    <StandardBtn type="edit" @click="editRecord(value)" />
                    <StandardBtn
                        type="delete"
                        @click="deletePayout(value.id)"
                    />
                </div>
            </template>
        </Vue3Datatable>
    </div>
</template>
<script setup>
import Vue3Datatable from "@bhplugin/vue3-datatable";
import Swal from "sweetalert2";
import { ref, computed, defineEmits, defineProps, watch, nextTick } from "vue";
import {
    // createPayout,
    // updatePayout,
    // deletePayoutRequest,
    createPayout,
    updatePayout,
    deletePayoutRequest,
    // fetchFilterData2,
} from "@/components/publishers/service";
import { useRoute } from "vue-router";
const Route = useRoute();
import { useStore } from "vuex";
import CountryInfo from "@/components/global/CountryInfo.vue";
const pageSize = ref(10);
const page = ref(1);
function handlePageChange(value) {
    page.value = value;
}
function handlePageSizeChange(value) {
    pageSize.value = value;
}
const cols = ref([
    {
        field: "id",
        title: "ID",
        visible: true,
        sortable: true,
    },
    {
        field: "country_ids",
        title: "country",
        visible: true,
        sortable: true,
    },
    {
        field: "amount",
        title: "Amount",
        visible: true,
        sortable: true,
    },
    // {
    //     field: "advertiser_id",
    //     title: "advertiser",
    //     visible: true,
    //     sortable: true,
    // },
    {
        field: "actions",
        title: "Actions",
        visible: true,
    },
]);
// const advertisers = ref([]);
// const getAdvertisers = (id) => {
//     const Advertiser = advertisers.value.find((p) => p.id == id);
//     return Advertiser ? Advertiser.name : id;
// };
// onMounted(async () => {
//     const filterData = await fetchFilterData2();
//     advertisers.value = await filterData["Advertisers"]["data"];
// });
const Emit = defineEmits(["reloadApi"]);
const Props = defineProps({
    payout: {
        type: [Object, Array],
        required: true,
        default: () => [],
    },
});
const payoutData = ref([]);
watch(
    () => Props.payout,
    (newVal) => {
        console.log("🚀 ~ file: DataTableComponent.vue:162 ~ newVal:", newVal);
        payoutData.value = newVal;
    },
    { immediate: true },
);
const store = useStore();
const payoutFormRef = ref(null);
const form = ref({
    // {
    // "affiliate_id" : "29",
    // "country_idss":"3",
    // "amount" : 11
    // }
    id: null,
    country_ids: "",
    amount: "",
});
const dialog = ref(false);
const countries = computed(() => store.getters.getCountries);
// Method to handle form submission
const loading = ref(false);
async function submitForm() {
    const { valid } = await payoutFormRef.value.validate();
    if (!valid) {
        return;
    }
    loading.value = true;
    form.value.affiliate_id = Route.params.id;
    if (form.value.id) {
        const data = await updatePayout(form.value);
        if (data) {
            Emit("reloadApi");
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Payout updated successfully.",
            });
            dialog.value = false;
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to update payout. Please try again.",
            });
        }
    } else {
        delete form.value.id;
        // we do like this because of api need this data otherwise it give server error
        const countryId = form.value.country_ids.toString();
        form.value.country_ids = countryId;
        const Amount = form.value.amount;
        form.value.amount = Number(Amount);
        const data = await createPayout(form.value);
        if (data) {
            Emit("reloadApi");
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Payout created successfully.",
            });
            dialog.value = false;
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to create payout. Please try again.",
            });
        }
    }
    loading.value = false;
}
function deletePayout(id) {
    Swal.fire({
        title: "Are you sure?",
        text: `You are sure about to delete`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                // Call delete API
                const data = await deletePayoutRequest({ payout_id: id });
                // Display success alert
                if (data) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Record deleted successfully.",
                    });
                    payoutData.value = payoutData.value.filter(
                        (row) => row.id !== id,
                    );
                } else {
                    throw new Error("Failed to delete");
                }
            } catch (error) {
                // Handle error and display an error alert
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to delete. Please try again.",
                });
                console.error("Failed to delete:", error);
            }
        }
    });
}
const editRecord = (record) => {
    console.log(
        "🚀 ~ file: DataTableComponent.vue:251 ~ editRecord ~ record:",
        record,
    );

    //   {
    //     "id": 69,
    //     "amount": "900.00",
    //     "advertiser_id": 0,
    //     "affiliate_id": 22,
    //     "country_ids": "75",
    //     "created_at": "2024-09-16T09:22:15.000000Z",
    //     "updated_at": "2024-09-16T09:22:15.000000Z",
    //     "is_global": 0,
    //     "country": {
    //         "id": 75,
    //         "name": "France",
    //         "country_icon": "https://ipdata.co/flags/fr.png",
    //         "code": "FR",
    //         "phoneCode": "33",
    //         "created_at": null,
    //         "updated_at": null
    //     }
    // }
    // eslint-disable-next-line no-unreachable
    console.log("Edit Record:", record);
    form.value.country_ids = Number(record.country_ids);
    form.value.amount = record.amount;
    form.value.id = record.id;
    dialog.value = true;
};
const addRecord = async () => {
    // we reset id like this because this id not use in v-form
    form.value.id = null;
    dialog.value = true;
    nextTick(() => {
        payoutFormRef.value.reset(); // reset form
        // payoutFormRef.value.resetValidation(); // reset validation only not form
    });
};
</script>
