// src/main.js
import { createApp } from "vue";
import App from "./App.vue";
import formatCurrency from "@/utils/currenyPrefix.js";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import { createVuetify } from "vuetify";
import "vuetify/styles";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { VTimePicker } from "vuetify/labs/VTimePicker";
import VueApexCharts from "vue3-apexcharts";
import "@mdi/font/css/materialdesignicons.css";
import "./axios";
import { registerGlobalComponents } from "./plugins/globalComponents";
const vuetify = createVuetify({
    theme: {
        themes: {
            light: {
                colors: {
                    bar: "#47246b",
                },
            },
        },
    },
    components: {
        ...components,
        VTimePicker,
    },
    directives,
});
const app = createApp(App);
registerGlobalComponents(app);
app.config.globalProperties.$formatCurrency = formatCurrency;
app.use(VueApexCharts).use(vuetify).use(store).use(router).mount("#app");
