<template>
    <v-row>
        <v-col md="3">
            <FilterComponent @apply-filters="handleApplyFilters" />
        </v-col>
        <v-col md="9">
            <DataTableComponent :filterData="currentFilterData" />
        </v-col>
    </v-row>
</template>

<script>
import FilterComponent from "../components/distributions/FilterComponent.vue";
import DataTableComponent from "../components/distributions/DataTableComponent.vue";

export default {
    name: "DistributionsView",
    components: {
        FilterComponent,
        DataTableComponent,
    },
    data() {
        return {
            currentFilterData: {},
        };
    },
    methods: {
        handleApplyFilters(filterData) {
            this.currentFilterData = filterData;
        },
    },
};
</script>

<style scoped></style>
