// src/router.js
import { createRouter, createWebHistory } from "vue-router";
import LeadsView from "./views/LeadsView.vue";
import PendingConversionView from "./views/PendingConversionView.vue";
import DashboardView from "./views/DashboardView.vue";
import AdvertisersView from "./views/advertisers/AdvertisersView.vue";
import DistributionsView from "./views/DistributionsView.vue";
import PublishersView from "./views/publishers/PublishersView.vue";
import UsersView from "./views/UsersView.vue";
import leadStatusGroupView from "./views/leadStatusGroup/leadStatusGroupView.vue";
import CreateAdvertiserView from "./views/advertisers/CreateAdvertiserView.vue";
import CreatePublisherView from "./views/publishers/CreatePublisherView.vue";
import SettingsView from "./views/SettingsView.vue";
import CapsView from "./views/CapsView.vue";
import EditAdvertiserView from "./views/advertisers/EditAdvertiserView.vue";
import EditPublisherView from "./views/publishers/EditPublisherView.vue";
import LoginView from "./views/LoginView.vue"; // Import the new Login view
import ReportsComponent from "./components/Reports/ReportsTable.vue";
import ClickReportsComponent from "./views/ClickReportsView.vue";
import OffersView from "./views/OffersView.vue";
import CampaignsView from "./views/CampaignsView.vue";
import RolesView from "./views/RolesView.vue";

import { useStore } from "vuex";
const routes = [
    {
        path: "/caps",
        name: "CapsView",
        component: CapsView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/reports",
        name: "ReportsView",
        component: ReportsComponent,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/offers",
        name: "OffersView",
        component: OffersView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/roles",
        name: "RolesView",
        component: RolesView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/campaigns",
        name: "CampaignsView",
        component: CampaignsView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/click-reports",
        name: "ClickReportsView",
        component: ClickReportsComponent,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/leads",
        name: "LeadsView",
        component: LeadsView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/pending-conversion",
        name: "PendingConversionView",
        component: PendingConversionView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/advertisers",
        name: "AdvertisersView",
        component: AdvertisersView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/create/advertiser",
        name: "CreateAdvertiserView",
        component: CreateAdvertiserView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit/advertiser/:id", // Use :id for dynamic segment
        name: "AdvertiserEditView",
        component: EditAdvertiserView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit/affiliate/:id", // Use :id for dynamic segment
        name: "PublisherEditView",
        component: EditPublisherView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/rotations",
        name: "DistributionsView",
        component: DistributionsView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/affiliates",
        name: "PublishersView",
        component: PublishersView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/create/affiliates",
        name: "CreatePublisherView",
        component: CreatePublisherView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/users",
        name: "UsersView",
        component: UsersView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/leadStatusGroup",
        name: "leadStatusGroupView",
        component: leadStatusGroupView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/settings",
        name: "SettingsView",
        component: SettingsView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/",
        name: "DashboardView",
        component: DashboardView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/login", // Define the path for the login page
        name: "LoginView", // Name of the route
        component: LoginView, // Component to render
        meta: {
            requiresAuth: false,
        },
    },
    // handle 404
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        meta: {
            requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
            next({ path: "/" });
        },
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach(async (to, from, next) => {
    const store = useStore();
    if (to.meta.requiresAuth && !store.getters.getUser?.token) {
        next({ path: "/login" });
    } else if (!to.meta.requiresAuth && store.getters.getUser?.token) {
        next({ path: "/" });
    } else {
        next();
    }
});
export default router;
