<template>
    <div>
        <v-dialog v-model="dialog" max-width="500px" persistent>
            <v-form ref="capRef">
                <v-card>
                    <v-card-title>
                        <span class="headline" v-if="editId === null"
                            >Add New Cap</span
                        >
                        <span class="headline" v-else>Edit Cap</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <v-select
                                        label="Type"
                                        v-model="capType"
                                        :items="[
                                            {
                                                text: 'Lead',
                                                value: 'lead',
                                            },
                                            {
                                                text: 'FTD',
                                                value: 'ftd',
                                            },
                                        ]"
                                        item-title="text"
                                        item-value="value"
                                        variant="outlined"
                                        :rules="[
                                            (value) =>
                                                !!value ||
                                                'Please select a valid type',
                                        ]"
                                    ></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <!-- :items="['Daily', 'Interval']" -->
                                    <v-select
                                        item-title="text"
                                        item-value="value"
                                        :items="[
                                            {
                                                text: 'Daily',
                                                value: 'daily',
                                            },
                                            {
                                                text: 'Interval',
                                                value: 'interval',
                                            },
                                        ]"
                                        label="Interval"
                                        v-model="interval"
                                        variant="outlined"
                                        :rules="[
                                            (value) =>
                                                !!value ||
                                                'Please select a valid interval',
                                        ]"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="resetAt"
                                        label="Enter time (HH:MM)"
                                        placeholder="HH:MM"
                                        variant="outlined"
                                        :rules="[
                                            (value) =>
                                                validateTime(value) ||
                                                'Please enter a valid time',
                                        ]"
                                        prepend-inner-icon="mdi-clock-outline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <TimeZoneCom
                                        id
                                        label="Timezone"
                                        v-model="timezone"
                                        :items="timezones"
                                        item-title="name"
                                        item-value="id"
                                        variant="outlined"
                                        :rules="[
                                            (v) =>
                                                !!v || 'Timezone is required',
                                        ]"
                                    >
                                    </TimeZoneCom>
                                    <!-- <v-autocomplete
                                        label="Timezone"
                                        v-model="timezone"
                                        :items="timezones"
                                        item-title="name"
                                        item-value="id"
                                        variant="outlined"
                                        :rules="[
                                            (v) =>
                                                !!v || 'Timezone is required',
                                        ]"
                                    >
                                    </v-autocomplete> -->
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Cap"
                                        v-model="cap"
                                        variant="outlined"
                                        type="number"
                                        :rules="[
                                            (value) =>
                                                value >= 0 ||
                                                'Cap should be greater than equal to 0',
                                        ]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete
                                        label="Countries"
                                        v-model="selectedCountry"
                                        variant="outlined"
                                        :items="countries"
                                        item-title="name"
                                        item-value="id"
                                        multiple
                                        clearable
                                    >
                                        <!-- :rules="[
                                        (value) =>
                                            value.length > 0 ||
                                            'Please select a valid country',
                                    ]" -->
                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <!-- {{ item.raw }} -->
                                            <v-chip
                                                small
                                                @click="
                                                    selectedCountry.splice(
                                                        index,
                                                        1,
                                                    )
                                                "
                                                close
                                                class="p-2"
                                            >
                                                <img
                                                    :src="item.raw.country_icon"
                                                    :alt="item.raw.code"
                                                    class="img-flags"
                                                />
                                                &nbsp;
                                                {{ item.raw.name }}
                                            </v-chip>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <!-- {{ publishers }} -->
                                    <!-- {{ selectedPublishers }} -->
                                    <v-combobox
                                        v-model="selectedPublishers"
                                        :items="publishers"
                                        label="Publishers"
                                        :disabled="true"
                                        item-title="name"
                                        item-value="id"
                                        multiple
                                        variant="outlined"
                                        clearable
                                    >
                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <v-chip
                                                small
                                                @click="
                                                    selectedPublishers.splice(
                                                        index,
                                                        1,
                                                    )
                                                "
                                                close
                                            >
                                                {{ item.title }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="12">
                                    <v-combobox
                                        v-model="selectedAdvertisers"
                                        :items="advertisers"
                                        label="Advertisers"
                                        item-title="name"
                                        item-value="id"
                                        :return-object="false"
                                        multiple
                                        variant="outlined"
                                        clearable
                                    >
                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <v-chip
                                                small
                                                @click="
                                                    selectedAdvertisers.splice(
                                                        index,
                                                        1,
                                                    )
                                                "
                                                close
                                            >
                                                {{ item.title }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="12">
                                    <v-list-subheader class="text-uppercase">
                                        <!-- <v-icon>mdi-tune</v-icon> -->
                                        Parameters
                                    </v-list-subheader>
                                    <!-- <v-list-item> -->
                                    <!-- <v-col> -->
                                    <div
                                        class="d-flex -mx- flex-wrap flex-md-nowrap gap-4 justify-center"
                                    >
                                        <v-autocomplete
                                            label="Parameter"
                                            :disabled="editId !== null"
                                            class="w-100"
                                            v-model="parameterLabel"
                                            :items="parameterLabels"
                                            placeholder="Select a parameter"
                                            item-title="name"
                                            return-object
                                            variant="outlined"
                                            hide-details
                                            clearable
                                        >
                                        </v-autocomplete>
                                        <v-autocomplete
                                            label="Value"
                                            :disabled="editId !== null"
                                            class="w-100"
                                            v-model="parameterValue"
                                            :items="parameterValues"
                                            placeholder="Select a value"
                                            item-title="name"
                                            return-object
                                            variant="outlined"
                                            hide-details
                                            clearable
                                        >
                                        </v-autocomplete>
                                        <StandardBtn
                                            type="create"
                                            @click="addParameter"
                                            :disabled="
                                                !parameterLabel ||
                                                !parameterValue
                                            "
                                        />
                                    </div>
                                    <!-- </v-col> -->
                                    <div v-if="selectedParameters.length > 0">
                                        <v-list>
                                            <v-list-item
                                                v-for="(
                                                    parameter, index
                                                ) in selectedParameters"
                                                :key="`parameter-${index}`"
                                                class="border"
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title>{{
                                                        parameter.label.name
                                                    }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{
                                                        parameter.value.name
                                                    }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <template v-slot:append>
                                                    <StandardBtn
                                                        type="delete"
                                                        variant="text"
                                                        @click="
                                                            removeParameter(
                                                                index,
                                                            )
                                                        "
                                                    />
                                                </template>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                    <div v-else class="text-center">
                                        No parameters
                                    </div>
                                    <!-- </v-list-item> -->
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                            >Cancel</v-btn
                        >
                        <v-btn color="blue darken-1" @click="saveCaps">
                            {{ editId === null ? "Create" : "Save" }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <!-- Dropdown for column visibility -->
        <!-- <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="dropdown" ref="dropdown">
                <button
                    class="btn-shadow btn btn-dark btn-sm dense"
                    @click="toggleDropdown"
                >
                    Columns
                </button>
                <div
                    v-show="dropdownOpen"
                    class="dropdown-content"
                    style="margin-top: 0px"
                >
                    <label v-for="col in allColumns" :key="col.field">
                        <input
                            type="checkbox"
                            :checked="col.visible"
                            @change="() => toggleColumnVisibility(col.field)"
                        />
                        {{ col.title }}
                    </label>
                </div>
            </div>
            <a @click="createUser()" class="btn btn-primary float-end mb-4">
                <span>+</span>
            </a>
        </div> -->
        <div class="statusDiv mb-4">
            <!-- <v-btn @click="addRecord">Create</v-btn> -->
            <StandardBtn type="create" @click="addRecord" />
        </div>
        <!-- DataTable Component -->
        <div class="bh-table-responsive table-container mb-5 mt-2">
            <Vue3Datatable
                @pageChange="handlePageChange"
                @pageSizeChange="handlePageSizeChange"
                :paginationInfo="
                    'Showing {0} to {1} of {2} entries' +
                    ` | Page ${page} of ${Math.ceil(capData.length / pageSize)}`
                "
                :stickyHeader="true"
                :columns="allColumns"
                :rows="capData"
                :sortable="true"
                :loading="loading"
                skin="bh-table-bordered bh-table-responsive"
            >
                <template #interval="{ value }">
                    <v-chip class="ma-1 bg-primary" label>
                        {{ value.interval }}
                    </v-chip>
                </template>
                <template #type="{ value }">
                    <v-chip class="ma-1 bg-secondary" label>
                        {{ value.type }}
                    </v-chip>
                </template>
                <template #filled="{ value }">
                    <!-- {{  }} -->
                    <v-tooltip :text="`${value.filled}/${value.cap}`">
                        <template v-slot:activator="{ props }">
                            <v-progress-circular
                                v-bind="props"
                                :model-value="
                                    calculateProgress(value.filled, value.cap)
                                "
                                :size="50"
                                :rotate="360"
                                :width="10"
                                :color="
                                    calculateProgress(value.filled, value.cap) >
                                    80
                                        ? 'success'
                                        : calculateProgress(
                                                value.filled,
                                                value.cap,
                                            ) >= 50
                                          ? 'warning'
                                          : 'error'
                                "
                            >
                                <span class="x-small">
                                    {{ value.filled }}
                                </span>
                            </v-progress-circular>
                        </template>
                    </v-tooltip>
                    &nbsp; / {{ value.cap }}
                </template>
                <template #advertisers="{ value }">
                    <v-chip
                        v-for="advertiser in value?.advertisers"
                        :key="advertiser"
                        class="ma-1"
                        label
                    >
                        {{ advertiser?.name }}
                    </v-chip>
                </template>
                <template #affiliates="{ value }">
                    <v-chip
                        v-for="affiliate in value.affiliates"
                        :key="affiliate"
                        class="ma-1"
                        label
                    >
                        {{ affiliate.name }}
                    </v-chip>
                </template>
                <template #reset_time="{ value }">
                    {{ value?.reset_time?.slice(0, -3) }}
                </template>
                <template #countries="{ value }">
                    <span
                        v-for="(country, index) in value.countries"
                        :key="country"
                        class="ma-1"
                        label
                    >
                        <CountryInfo :id="country.id" />
                        <span v-if="index !== value.countries?.length - 1">
                            &comma;
                        </span>
                    </span>
                </template>
                <template #timezone="{ value }">
                    <v-chip class="ma-1" label>
                        <!-- {{ value?.timezone?.name }} -->
                        {{ findTimezone(value.timezone_id) }}
                    </v-chip>
                </template>
                <!-- Actions -->
                <template #actions="{ value }">
                    <div class="d-flex gap-2">
                        <StandardBtn type="edit" @click="editCapsRow(value)" />
                        <StandardBtn
                            type="delete"
                            @click="deleteCapsRow(value.id)"
                        />
                        <!-- <button
                            type="button"
                            class="btn btn-outline-success"
                            @click="editCapsRow(value.id)"
                        >
                            <i class="bi bi-check-circle"></i>
                            Edit
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-danger"
                            @click="deleteCapsRow(value.id)"
                        >
                            <i class="bi bi-x-circle"></i>
                            Delete
                        </button> -->
                    </div>
                </template>
            </Vue3Datatable>
        </div>
        <!-- Edit Modal -->
        <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
            ref="editModal"
        >
            <EditComponent :editId="editId" />
        </div>
        <div
            class="modal fade"
            id="createModal"
            tabindex="-1"
            aria-labelledby="creatModalLabel"
            aria-hidden="true"
            ref="createModal"
        >
            <CreateComponent />
        </div>
    </div>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import {
    ref,
    computed,
    watch,
    defineProps,
    defineEmits,
    onMounted,
    reactive,
    onBeforeUnmount,
    nextTick,
} from "vue";
// /src/components/caps/service.js
import {
    fetchFilterData,
    fetchCaps,
    createCaps,
    updateCaps,
    deleteCaps,
    fetchTimezones,
} from "../../../components/caps/service";
import { fetchFilterData2 } from "@/components/publishers/service";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
import axios from "axios";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import EditComponent from "./EditComponent.vue";
import CreateComponent from "./CreateComponent.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import CountryInfo from "@/components/global/CountryInfo.vue";
import TimeZoneCom from "@/components/global/autoComplete/timeZoneCom.vue";
const Route = useRoute();
const store = useStore();
const apiUrl = computed(() => process.env.VUE_APP_API_URL);

const pageSize = ref(10);
const page = ref(1);
function handlePageChange(value) {
    page.value = value;
}
function handlePageSizeChange(value) {
    pageSize.value = value;
}
const allColumns = ref([
    {
        field: "interval",
        title: "Interval",
        visible: true,
        sortable: true,
    },
    {
        field: "type",
        title: "Type",
        visible: true,
        sortable: true,
    },
    {
        field: "filled",
        title: "Filled",
        visible: true,
        sortable: true,
    },
    {
        field: "countries",
        title: "Countries",
        visible: true,
        sortable: true,
    },
    {
        field: "advertisers",
        title: "Advertiser",
        visible: true,
        sortable: true,
    },
    {
        field: "affiliates",
        title: "Publisher",
        visible: true,
        sortable: true,
    },
    {
        field: "reset_time",
        title: "Reset At",
        visible: true,
        sortable: true,
    },
    {
        field: "timezone",
        title: "Timezone",
        visible: true,
        sortable: true,
    },
    {
        field: "actions",
        title: "Actions",
        visible: true,
        sortable: false,
    },
]);
// parameter
const parameterLabels = ref([]);
const filterData = ref([]);
const parameterValues = computed(() => {
    if (filterData.value && parameterLabel.value?.name) {
        return filterData.value[parameterLabel.value["name"]]["data"];
    } else return [];
});
const parameterLabel = ref("");
const parameterValue = ref("");
const selectedParameters = ref([]);
function addParameter() {
    // user cannot select the same parameter twice, so remove the selected from the paramLabels
    parameterLabels.value = parameterLabels.value.filter(
        (value) => value.id !== parameterLabel.value.id,
    );
    selectedParameters.value.push({
        label: parameterLabel.value,
        value: parameterValue.value,
    });
    parameterLabel.value = "";
    parameterValue.value = "";
}
function removeParameter(index) {
    parameterLabels.value.push(selectedParameters.value[index].label);
    selectedParameters.value.splice(index, 1);
}
// ending
const loading = ref(false);
const Props = defineProps({
    cap: {
        type: [Object, Array],
        default: () => [],
    },
});
const dialog = ref(false);
const capData = ref([]);
watch(
    () => Props.cap,
    (newVal) => {
        capData.value = newVal;
    },
    { immediate: true },
);
const Emit = defineEmits(["reloadApi"]);
// const editRecord = (record) => {
//     console.log("Edit Record:", record);
//     // form.value.country_id = record.country.id;
//     // form.value.amount = record.amount;
//     // form.value.revenue_id = record.id;
//     dialog.value = true;
// };
const addRecord = async () => {
    dialog.value = true;
    nextTick(() => {
        // capRef.value.reset();
        // selectedPublishers.value = [Number(Route.params.id)];

        // selectedParameters.value = [];
        editId.value = null;
        selectedPublishers.value = [Number(Route.params.id)];
        selectedParameters.value = [];
        interval.value = "daily";
        capType.value = "lead";
        resetAt.value = "00:00";
        cap.value = "0";
        // timezone.value = Number(241);
        selectedCountry.value = [];
        selectedAdvertisers.value = [];
        // parameterLabels.value = [];
        // parameterValue.value = "";
    });
};
const submitForm = async () => {
    // console.log("Form Data:", form.value);
    dialog.value = false;
};
const params = reactive({
    current_page: 1,
    pagesize: 100,
});
// Interval	Type Filled	Countries	Advertiser	Publisher	Reset At	Timezone	Action
const rows = ref([]);
const dropdownOpen = ref(false);
const dropdown = ref(null);
const interval = ref("");
const editId = ref(null);
const capType = ref("");
const resetAt = ref(null);
const resetAtMenu = ref(false);
const timezone = ref("");
const timezones = ref([]);
const findTimezone = (id) => {
    const timezone = timezones.value.find((timezone) => timezone.id == id);
    return timezone ? timezone.name : id;
};
const cap = ref(0);
const countries = computed(() => store.getters.getCountries);
const selectedCountry = ref([]);
const publishers = ref([]);
const advertisers = ref([]);
const selectedAdvertisers = ref([]);
const progress = ref(0);
function validateTime(time) {
    if (!time) {
        return false;
    }
    const timeParts = time.split(":");
    if (timeParts.length !== 2) {
        return false;
    }
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    if (timeParts[0].length !== 2 || timeParts[1].length !== 2) {
        return false;
    }
    if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
        return true;
    }
    return false;
}
function calculateProgress(filled, cap) {
    if (cap === 0) {
        return 100;
    }
    return Math.round((filled / cap) * 100);
}
function getCountry(id) {
    return countries.value.find((obj) => obj?.id === id);
}
function toggleDropdown() {
    dropdownOpen.value = !dropdownOpen.value;
}
// const editModal = ref(null);
// const createModal = ref(null);
async function editCapsRow(value) {
    // enable editId
    editId.value = await value;
    console.log(
        "🚀 ~ file: DataTableComponent.vue:617 ~ editCapsRow ~ editId.value:",
        editId.value,
    );
    // autofill form
    // const selectedRowData = await capData.value?.find((row) => row?.id === id);
    // console.log(
    //     "🚀 ~ file: DataTableComponent.vue:627 ~ editCapsRow ~ selectedRowData:",
    //     selectedRowData,
    // );
    // selectedPublishers.value = selectedRowData?.affiliate_id;
    selectedAdvertisers.value = value?.advertisers.map(
        (advertiser) => advertiser.id,
    );
    timezone.value = value?.timezone_id;
    selectedCountry.value = value?.countries.map((country) => country.id);
    interval.value = value?.interval;
    capType.value = value?.type;
    // type.value = value?.type;
    resetAt.value =
        value?.reset_time?.split(":")[0] +
        ":" +
        value?.reset_time?.split(":")[1];
    cap.value = value?.cap;
    dialog.value = true;
}
async function createRow() {
    // fetch fill the autocomplete
    editId.value = null;
    selectedPublishers.value = [];
    selectedAdvertisers.value = [];
    selectedCountry.value = [];
    interval.value = "";
    capType.value = "";
    resetAt.value = null;
    timezone.value = "";
    cap.value = 0;
    // show modal
    dialog.value = true;
}
const capRef = ref(null);
async function saveCaps() {
    const { valid } = await capRef.value.validate();
    console.log(valid);

    if (!valid) {
        return;
    }
    const data = {
        advertiser_ids: selectedAdvertisers.value,
        type: capType.value,
        interval: interval.value,
        cap: String(cap.value),
        timezone_id: timezone.value,
        country_ids: selectedCountry.value,
        affiliate_ids: selectedPublishers.value,
        reset_time: resetAt.value || "00:00",
        parameters: Object.fromEntries(
            selectedParameters.value.map((parameter) => [
                parameter.label.id,
                parameter.value.id,
            ]),
        ),
    };
    try {
        if (editId.value !== null) {
            // edit cap
            data.cap_id = editId.value;
            const response = await updateCaps(data);
            if (!response) {
                throw new Error("Failed to update");
            }
            Emit("reloadApi");
            Swal.fire({
                icon: "success",
                title: "Successfully updated",
                text: "Cap updated successfully",
            });
            // console.log("Edit Cap", response);
        } else {
            console.log("Create Cap payload", data);
            const response = await createCaps(data);
            if (!response) {
                throw new Error("Failed to create");
            }
            Emit("reloadApi");
            Swal.fire({
                icon: "success",
                title: "Successfully created",
                text: "Cap created successfully",
            });
            // console.log("Create Cap", response);
        }
    } catch (error) {
        console.error("Failed to save Cap:", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to save Cap. Please try again.",
        });
    } finally {
        dialog.value = false;
    }
}
function deleteCapsRow(id) {
    console.log("Reject Lead: " + id);
    Swal.fire({
        title: "Are you sure?",
        text: `You are sure about to delete`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                // Make the POST request with axios
                const data = await deleteCaps(id);
                if (data) {
                    Emit("reloadApi");
                    Swal.fire({
                        icon: "success",
                        title: "Successfully deleted",
                        text: data.message,
                    });
                } else {
                    throw new Error("Failed to delete");
                }
            } catch (error) {
                // Handle error and display an error alert
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to delete. Please try again.",
                });
                console.error("Failed to delete:", error);
            }
        }
    });
}
function toggleColumnVisibility(field) {
    const column = allColumns.value.find((col) => col.field === field);
    if (column) {
        column.visible = !column.visible;
    }
}
const visibleColumns = computed(() => {
    return allColumns.value.filter((col) => col.visible);
});
function handleClickOutside(event) {
    if (dropdown.value && !dropdown.value.contains(event.target)) {
        dropdownOpen.value = false;
    }
}
const selectedPublishers = ref([]);
onMounted(async () => {
    // rows.value = (await fetchCaps()) || [];
    filterData.value = await fetchFilterData2();
    for (const parameter in filterData.value) {
        parameterLabels.value.push({
            name: parameter,
            id: filterData.value[parameter].id,
        });
        parameterValues.value = [
            ...parameterValues.value,
            ...filterData.value[parameter].data,
        ];
    }
    timezones.value = (await fetchTimezones()) || [];
    publishers.value = (await fetchFilterData()).publishers || [];
    advertisers.value = (await fetchFilterData()).advertisers || [];
    // selectedPublishers.value.push(Number(Route?.params?.id));
    if (selectedPublishers.value) {
        selectedPublishers.value = [Number(Route.params.id)];
    }
    document.addEventListener("click", handleClickOutside);
});
onBeforeUnmount(() => {
    document.removeEventListener("click", handleClickOutside);
});
// const rows = ref([]);
// const dropdownOpen = ref(false);
// const dropdown = ref(null);
// const countries = computed(() => store.getters.getCountries);
// const editId = ref(null);
// const getCountryCode = (countryName) => {
//     const country = countries.value.find((c) => c.name === countryName);
//     return country ? country.code.toLowerCase() : "default"; // Ensure code is lowercase
// };
// function toggleDropdown() {
//     dropdownOpen.value = !dropdownOpen.value;
// }
// const editModal = ref(null);
// const createModal = ref(null);
// function createUser() {
//     nextTick(() => {
//         if (createModal.value) {
//             const modalInstance = new Modal(createModal.value);
//             modalInstance.show();
//         }
//     });
// }
// function editLead(id) {
//     console.log("Accept Lead: " + id);
//     editId.value = id;
//     nextTick(() => {
//         if (editModal.value) {
//             const modalInstance = new Modal(editModal.value);
//             modalInstance.show();
//         }
//     });
// }
// function deleteLeads(id) {
//     console.log("Reject Lead: " + id);
//     Swal.fire({
//         title: "Are you sure?",
//         text: `You are sure about to delete`,
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Yes, delete it!",
//         cancelButtonText: "No, cancel",
//     }).then(async (result) => {
//         if (result.isConfirmed) {
//             const url = `/api/users/${id}/delete`;
//             try {
//                 // Make the POST request with axios
//                 const response = await axios.delete(url, {
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                 });
//                 // Check if the response has a message
//                 if (response.data && response.data.message) {
//                     // Display success alert if the response contains the message
//                     Swal.fire({
//                         icon: "success",
//                         title: "Successfully deleted",
//                         text: response.data.message,
//                     });
//                 } else {
//                     throw new Error("Unexpected response format");
//                 }
//             } catch (error) {
//                 // Handle error and display an error alert
//                 Swal.fire({
//                     icon: "error",
//                     title: "Error",
//                     text: "Failed to delete. Please try again.",
//                 });
//                 console.error("Failed to delete:", error);
//             }
//         }
//     });
// }
// function toggleColumnVisibility(field) {
//     const column = allColumns.value.find((col) => col.field === field);
//     if (column) {
//         column.visible = !column.visible;
//     }
// }
// const visibleColumns = computed(() => {
//     return allColumns.value.filter((col) => col.visible);
// });
// // const filteredRows = computed(() => {
// //     return rows.value.filter((row) => {
// //         return Object.keys(props.filterData.filters || {}).every((key) => {
// //             return (
// //                 !props.filterData.filters[key] ||
// //                 row[key] === props.filterData.filters[key]
// //             );
// //         });
// //     });
// // });
// async function fetchDataWithFilter() {
//     const url = `/api/users`;
//     console.log("Fetching data from URL:", url);
//     try {
//         const { data } = await axios.get(url);
//         rows.value = data; // Assuming rows is a reactive variable in Vue 3
//         console.log("Received data:", data);
//     } catch (error) {
//         console.error("Failed to fetch data:", error);
//     }
// }
// function handleClickOutside(event) {
//     if (dropdown.value && !dropdown.value.contains(event.target)) {
//         dropdownOpen.value = false;
//     }
// }
// onMounted(async () => {
//     fetchDataWithFilter();
//     document.addEventListener("click", handleClickOutside);
// });
// onBeforeUnmount(() => {
//     document.removeEventListener("click", handleClickOutside);
// });
</script>
<style scoped>
/* .table-container {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    } */
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
    padding: 10px;
    max-height: 400px;
    overflow: scroll;
}
.dropdown-content label {
    display: block;
    padding: 5px 0;
}
.dropdown-content label:hover {
    background-color: #f1f1f1;
}
.img-flags {
    width: 15px;
    height: 15px;
}
</style>
