// src/store/index.js
import { createStore } from "vuex";
import axios from "axios"; // Ensure axios is imported

const store = createStore({
    state: {
        user: null,
        filterData: null,
        countries: [], // State to hold countries
        timeZones: [], // State to hold time zones
    },
    getters: {
        getFilterData: (state) => state.filterData,
        getCountries: (state) => state.countries, // Getter to access countries
        getTimeZones: (state) => state.timeZones, // Getter to access time zones
        getUser: (state) => state.user, // Getter to access user
    },
    mutations: {
        setFilterData(state, value) {
            state.filterData = value;
        },
        setCountries(state, countries) {
            state.countries = countries;
        },
        setTimeZones(state, timeZones) {
            // Mutation to set time zones
            state.timeZones = timeZones;
        },
        setUser(state, user) {
            state.user = user;
        },
    },
    actions: {
        setFilterData({ commit }, payload) {
            commit("setFilterData", payload);
        },
        async fetchCountries({ commit }) {
            try {
                const res = await axios.get("/api/countries"); // Fetch countries from API
                commit("setCountries", res.data); // Commit the countries to the state
            } catch (error) {
                console.error("Error fetching countries:", error); // Handle error
            }
        },
        async fetchTimeZones({ commit }) {
            try {
                const res = await axios.get("/api/timezone"); // Fetch time zones from API
                commit("setTimeZones", res.data); // Commit the time zones to the state
            } catch (error) {
                console.error("Error fetching time zones:", error); // Handle error
            }
        },
        setUser({ commit }, user) {
            localStorage.setItem("user_data", JSON.stringify(user));
            commit("setUser", user);
        },
    },
});

export default store;
