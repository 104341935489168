<template>
    <div class="card" style="height: auto">
        <h2 style="margin: 20px">Filters</h2>
        <div class="" style="margin: 10px">
            <button class="btn btn-success" @click="applyFilters">Apply</button>
            <button class="btn btn-danger" @click="resetFilters">Cancel</button>
        </div>

        <!-- Existing filters -->
        <div class="form-group" v-for="(label, key) in filterFields" :key="key">
            <label :for="key">{{ label }}</label>
            <div class="relative-container">
                <input
                    type="text"
                    :id="key"
                    class="form-control"
                    v-model="filters[key]"
                    @input="handleInput(key)"
                    @focus="showSuggestions(key)"
                    @blur="hideSuggestions"
                />
                <ul
                    v-if="isDropdownVisible[key]"
                    class="dropdown-menu show"
                    @mousedown.stop
                >
                    <li
                        v-for="item in filteredSuggestions[key]"
                        :key="item.id || item.brand_status"
                        @click="selectItem(key, item)"
                    >
                        {{ item.name || item.brand_status }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="form-group">
            <label for="quickTimeRange">Method</label>
            <select
                class="form-select"
                id="quickTimeRange"
                v-model="quickTimeRange"
                @change="setQuickTimeRange"
            >
                <option value="slot">Slot</option>
                <option value="chance">Chance</option>
            </select>
        </div>

        <br />
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "FilterComponent",
    data() {
        return {
            filters: {
                advertiser_id: "",
                advertiser: "",
                countries_id: "",
                countries: "",
                publisher_id: "",
                publisher: "",

                statuses_id: "",
                statuses: "",
                startDate: "",
                endDate: "",
            },
            quickTimeRange: "", // New quick time range field
            suggestions: {
                countries: [],
            },
            isDropdownVisible: {
                countries: false,
            },
            includeFakeFTDs: "false",
            traffic: "All days (Mon - Sun)",
            filterFields: {
                countries: "Countries",
            },
            currentFocused: null,
            leadsCheckWithTime: false,
        };
    },
    computed: {
        filteredSuggestions() {
            const result = {};
            Object.keys(this.suggestions).forEach((key) => {
                const filter = this.filters[key].toLowerCase();
                result[key] = this.suggestions[key].filter((item) => {
                    const value = (
                        item.name ||
                        item.brand_status ||
                        ""
                    ).toLowerCase();
                    return value.includes(filter);
                });
            });
            return result;
        },
    },
    methods: {
        applyFilters() {
            const filterData = {
                filters: this.filters,
                includeFakeFTDs: this.includeFakeFTDs,
                traffic: this.traffic,
                leadsCheckWithTime: this.leadsCheckWithTime,
            };
            this.$emit("apply-filters", filterData);
        },
        async fetchFilterData() {
            try {
                const response = await axios.get("/api/get-filter-data");
                this.suggestions.countries = response.data.countries || [];

                this.suggestions.statuses = response.data.statuses || [];
                console.log("response of filter is", response);
            } catch (error) {
                console.error("Error fetching filter data:", error);
            }
        },
        handleInput(filterType) {
            if (this.filters[filterType].trim() === "") {
                this.showSuggestions(filterType);
            } else {
                this.isDropdownVisible[filterType] = true;
            }
        },
        showSuggestions(filterType) {
            this.currentFocused = filterType;
            Object.keys(this.isDropdownVisible).forEach((key) => {
                this.isDropdownVisible[key] = key === filterType;
            });
        },
        hideSuggestions() {
            setTimeout(() => {
                if (this.currentFocused !== null) {
                    this.isDropdownVisible[this.currentFocused] = false;
                    this.currentFocused = null;
                }
            }, 200);
        },
        selectItem(filterType, item) {
            this.filters[filterType] = item.name || item.brand_status;
            this.filters[filterType + "_id"] = item.id || item.brand_status;
            this.isDropdownVisible[filterType] = false;
        },
        resetFilters() {
            this.filters = {
                countries: "",
            };
            this.quickTimeRange = "";
            this.includeFakeFTDs = "false";
            this.traffic = "All days (Mon - Sun)";
            this.leadsCheckWithTime = false;
        },
        setQuickTimeRange() {
            const today = new Date();

            const dayOfWeek = today.getDay();
            const startOfWeek = new Date(today);
            if (dayOfWeek === 0) {
                startOfWeek.setDate(today.getDate() - 6);
            } else {
                startOfWeek.setDate(today.getDate() - (dayOfWeek - 1));
            }
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6);
            const startOfLastWeek = new Date(startOfWeek);
            startOfLastWeek.setDate(startOfWeek.getDate() - 7);
            const endOfLastWeek = new Date(endOfWeek);
            endOfLastWeek.setDate(endOfWeek.getDate() - 7);

            switch (this.quickTimeRange) {
                case "thisWeek":
                    this.filters.startDate = this.formatDate(startOfWeek);
                    this.filters.endDate = this.formatDate(new Date());
                    break;
                case "lastWeek":
                    this.filters.startDate = this.formatDate(startOfLastWeek);
                    this.filters.endDate = this.formatDate(endOfLastWeek);
                    break;
                case "thisMonth":
                    this.filters.startDate = this.formatDate(
                        new Date(today.getFullYear(), today.getMonth(), 1),
                    );
                    this.filters.endDate = this.formatDate(new Date());
                    break;
                case "lastMonth":
                    this.filters.startDate = this.formatDate(
                        new Date(today.getFullYear(), today.getMonth() - 1, 1),
                    );
                    this.filters.endDate = this.formatDate(
                        new Date(today.getFullYear(), today.getMonth(), 0),
                    );
                    break;
                case "thisYear":
                    this.filters.startDate = this.formatDate(
                        new Date(today.getFullYear(), 0, 1),
                    );
                    this.filters.endDate = this.formatDate(new Date());
                    break;
                case "lastYear":
                    this.filters.startDate = this.formatDate(
                        new Date(today.getFullYear() - 1, 0, 1),
                    );
                    this.filters.endDate = this.formatDate(
                        new Date(today.getFullYear() - 1, 11, 31),
                    );
                    break;
                default:
                    this.filters.startDate = "";
                    this.filters.endDate = "";
                    break;
            }
        },

        formatDate(date) {
            if (!date) return "";
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, "0");
            const day = String(d.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        handleClickOutside(event) {
            const dropdowns = document.querySelectorAll(".dropdown-menu");
            dropdowns.forEach((dropdown) => {
                if (!dropdown.contains(event.target)) {
                    this.hideSuggestions();
                }
            });
        },
    },
    mounted() {
        this.fetchFilterData();
        document.addEventListener("mousedown", this.handleClickOutside);
        // Automatically load data for the current week and apply the filters
        this.quickTimeRange = "thisWeek";
        this.setQuickTimeRange();
        this.applyFilters(); // Apply filters as soon as the component is mounted
    },
    beforeUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    },
};
</script>

<style scoped>
.relative-container {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    width: 100%;
    display: none;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-menu li {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #f8f9fa;
}
</style>
