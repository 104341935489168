<template>
    <div>
        <v-dialog v-model="dialog" max-width="500px" persistent>
            <v-form ref="capRef">
                <v-card>
                    <v-card-title>
                        <span class="headline" v-if="editId === null"
                            >Add New Cap</span
                        >
                        <span class="headline" v-else>Edit Cap</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <v-select
                                        label="Type"
                                        v-model="capType"
                                        :items="[
                                            {
                                                text: 'Lead',
                                                value: 'lead',
                                            },
                                            {
                                                text: 'FTD',
                                                value: 'ftd',
                                            },
                                        ]"
                                        item-title="text"
                                        item-value="value"
                                        variant="outlined"
                                        :rules="[
                                            (value) =>
                                                !!value ||
                                                'Please select a valid type',
                                        ]"
                                    ></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <!-- :items="['Daily', 'Interval']" -->
                                    <v-select
                                        item-title="text"
                                        item-value="value"
                                        :items="[
                                            {
                                                text: 'Daily',
                                                value: 'daily',
                                            },
                                            {
                                                text: 'Interval',
                                                value: 'interval',
                                            },
                                        ]"
                                        label="Interval"
                                        v-model="interval"
                                        variant="outlined"
                                        :rules="[
                                            (value) =>
                                                !!value ||
                                                'Please select a valid interval',
                                        ]"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="resetAt"
                                        label="Enter time (HH:MM)"
                                        placeholder="HH:MM"
                                        variant="outlined"
                                        :rules="[
                                            (value) =>
                                                validateTime(value) ||
                                                'Please enter a valid time',
                                        ]"
                                        prepend-inner-icon="mdi-clock-outline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <TimeZoneCom
                                        id
                                        label="Timezone"
                                        v-model="timezone"
                                        :items="timezones"
                                        item-title="name"
                                        item-value="id"
                                        variant="outlined"
                                        :rules="[
                                            (v) =>
                                                !!v || 'Timezone is required',
                                        ]"
                                    >
                                    </TimeZoneCom>
                                    <!-- <v-autocomplete
                                        label="Timezone"
                                        v-model="timezone"
                                        :items="timezones"
                                        item-title="name"
                                        item-value="id"
                                        variant="outlined"
                                        :rules="[
                                            (v) =>
                                                !!v || 'Timezone is required',
                                        ]"
                                    >
                                    </v-autocomplete> -->
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Cap"
                                        v-model="cap"
                                        variant="outlined"
                                        type="number"
                                        :rules="[
                                            (value) =>
                                                !!value ||
                                                'Please enter a valid cap',
                                            (value) =>
                                                value >= 0 ||
                                                'Cap should be greater than equal to 0',
                                        ]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete
                                        label="Countries"
                                        v-model="selectedCountry"
                                        variant="outlined"
                                        :items="countries"
                                        item-title="name"
                                        item-value="id"
                                        multiple
                                        clearable
                                    >
                                        <!-- :rules="[
                                        (value) =>
                                            value.length > 0 ||
                                            'Please select a valid country',
                                    ]" -->
                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <!-- {{ item.raw }} -->
                                            <v-chip
                                                small
                                                @click="
                                                    selectedCountry.splice(
                                                        index,
                                                        1,
                                                    )
                                                "
                                                close
                                                class="p-2"
                                            >
                                                <img
                                                    :src="item.raw.country_icon"
                                                    :alt="item.raw.code"
                                                    class="img-flags"
                                                />
                                                &nbsp;
                                                {{ item.raw.name }}
                                            </v-chip>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <!-- {{ publishers }} -->
                                    <!-- {{ selectedPublishers }} -->
                                    <v-combobox
                                        v-model="selectedPublishers"
                                        :items="publishers"
                                        label="Publishers"
                                        item-title="name"
                                        item-value="id"
                                        multiple
                                        :return-object="false"
                                        variant="outlined"
                                        clearable
                                    >
                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <v-chip
                                                small
                                                @click="
                                                    selectedPublishers.splice(
                                                        index,
                                                        1,
                                                    )
                                                "
                                                close
                                            >
                                                {{ item.title }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="12">
                                    <!-- {{ advertisers }}
                                    {{ selectedAdvertisers }} -->
                                    <v-combobox
                                        v-model="selectedAdvertisers"
                                        :items="advertisers"
                                        label="Advertisers"
                                        :disabled="true"
                                        item-title="name"
                                        item-value="id"
                                        :return-object="false"
                                        multiple
                                        variant="outlined"
                                        clearable
                                    >
                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <v-chip
                                                small
                                                @click="
                                                    selectedAdvertisers.splice(
                                                        index,
                                                        1,
                                                    )
                                                "
                                                close
                                            >
                                                {{ item.title }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="12">
                                    <v-list-subheader class="text-uppercase">
                                        <!-- <v-icon>mdi-tune</v-icon> -->
                                        Parameters
                                    </v-list-subheader>
                                    <div>
                                        <!-- <v-col> -->
                                        <div
                                            class="d-flex flex-wrap flex-md-nowrap gap-4 justify-center"
                                        >
                                            <v-autocomplete
                                                label="Parameter"
                                                :disabled="editId !== null"
                                                class="w-100"
                                                v-model="parameterLabel"
                                                :items="parameterLabels"
                                                placeholder="Select a parameter"
                                                item-title="name"
                                                return-object
                                                variant="outlined"
                                                hide-details
                                                clearable
                                            >
                                            </v-autocomplete>
                                            <v-autocomplete
                                                label="Value"
                                                :disabled="editId !== null"
                                                class="w-100"
                                                v-model="parameterValue"
                                                :items="parameterValues"
                                                placeholder="Select a value"
                                                item-title="name"
                                                return-object
                                                variant="outlined"
                                                hide-details
                                                clearable
                                            >
                                            </v-autocomplete>
                                            <StandardBtn
                                                type="create"
                                                @click="addParameter"
                                                :disabled="
                                                    !parameterLabel ||
                                                    !parameterValue
                                                "
                                            />
                                        </div>
                                        <!-- </v-col> -->
                                        <div
                                            style="margin-top: 10px"
                                            v-if="selectedParameters.length > 0"
                                        >
                                            <div>
                                                <v-list-item
                                                    v-for="(
                                                        parameter, index
                                                    ) in selectedParameters"
                                                    :key="`parameter-${index}`"
                                                    class="border"
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{
                                                            parameter.label.name
                                                        }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{
                                                            parameter.value.name
                                                        }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <template v-slot:append>
                                                        <StandardBtn
                                                            type="delete"
                                                            variant="text"
                                                            @click="
                                                                removeParameter(
                                                                    index,
                                                                )
                                                            "
                                                        />
                                                    </template>
                                                </v-list-item>
                                            </div>
                                        </div>
                                        <v-col v-else class="text-center">
                                            No parameters
                                        </v-col>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                            >Cancel</v-btn
                        >
                        <v-btn color="blue darken-1" @click="saveCaps">
                            {{ editId === null ? "Create" : "Save" }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <div class="statusDiv mb-4">
            <!-- <v-btn @click="addRecord">Create</v-btn> -->
            <StandardBtn type="create" @click="addRecord" />
        </div>
        <!-- DataTable Component -->
        <div class="bh-table-responsive table-container mb-5 mt-2">
            <Vue3Datatable
                @pageChange="handlePageChange"
                @pageSizeChange="handlePageSizeChange"
                :paginationInfo="
                    'Showing {0} to {1} of {2} entries' +
                    ` | Page ${page} of ${Math.ceil(capData.length / pageSize)}`
                "
                height="65vh"
                :stickyHeader="true"
                :columns="columns"
                :rows="capData"
                :sortable="true"
                :loading="loading"
                skin="bh-table-bordered bh-table-responsive"
            >
                <template #interval="{ value }">
                    <v-chip class="ma-1 bg-primary" label>
                        {{ value.interval }}
                    </v-chip>
                </template>
                <template #type="{ value }">
                    <v-chip class="ma-1 bg-secondary" label>
                        {{ value.type }}
                    </v-chip>
                </template>
                <template #filled="{ value }">
                    <v-tooltip :text="`${value.filled}/${value.cap}`">
                        <template v-slot:activator="{ props }">
                            <v-progress-circular
                                v-bind="props"
                                :model-value="
                                    calculateProgress(value.filled, value.cap)
                                "
                                :size="50"
                                :rotate="360"
                                :width="10"
                                :color="
                                    calculateProgress(value.filled, value.cap) >
                                    80
                                        ? 'success'
                                        : calculateProgress(
                                                value.filled,
                                                value.cap,
                                            ) >= 50
                                          ? 'warning'
                                          : 'error'
                                "
                            >
                                <span class="x-small">
                                    {{ value.filled }}
                                </span>
                            </v-progress-circular>
                        </template>
                    </v-tooltip>
                    &nbsp; / {{ value.cap }}
                </template>
                <template #advertisers="{ value }">
                    <v-chip
                        v-for="advertiser in value?.advertisers"
                        :key="advertiser"
                        class="ma-1"
                        label
                    >
                        {{ advertiser?.name }}
                    </v-chip>
                </template>
                <template #affiliates="{ value }">
                    <v-chip
                        v-for="affiliate in value.affiliates"
                        :key="affiliate"
                        class="ma-1"
                        label
                    >
                        {{ affiliate.name }}
                    </v-chip>
                </template>
                <template #reset_time="{ value }">
                    {{ value?.reset_time?.slice(0, -3) }}
                </template>
                <template #countries="{ value }">
                    <span
                        v-for="(country, index) in value.countries"
                        :key="country"
                        class="ma-1"
                        label
                    >
                        <CountryInfo :id="country.id" />
                        <span v-if="index !== value.countries?.length - 1">
                            &comma;
                        </span>
                    </span>
                </template>
                <template #timezone="{ value }">
                    <v-chip class="ma-1" label>
                        <!-- {{ value?.timezone?.name }} -->
                        {{ findTimezone(value.timezone_id) }}
                    </v-chip>
                </template>
                <!-- Actions -->
                <template #actions="{ value }">
                    <div class="d-flex gap-2">
                        <StandardBtn type="edit" @click="editCapsRow(value)" />
                        <StandardBtn
                            type="delete"
                            @click="deleteCapsRow(value.id)"
                        />
                        <!-- <button
                            type="button"
                            class="btn btn-outline-success"
                            @click="editCapsRow(value.id)"
                        >
                            <i class="bi bi-check-circle"></i>
                            Edit
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-danger"
                            @click="deleteCapsRow(value.id)"
                        >
                            <i class="bi bi-x-circle"></i>
                            Delete
                        </button> -->
                    </div>
                </template>
            </Vue3Datatable>
        </div>
        <!-- Edit Modal -->
        <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
            ref="editModal"
        >
            <EditComponent :editId="editId" />
        </div>
        <div
            class="modal fade"
            id="createModal"
            tabindex="-1"
            aria-labelledby="creatModalLabel"
            aria-hidden="true"
            ref="createModal"
        >
            <CreateComponent />
        </div>
    </div>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import {
    ref,
    computed,
    watch,
    defineProps,
    defineEmits,
    onMounted,
    reactive,
    onBeforeUnmount,
    nextTick,
} from "vue";
import {
    fetchFilterData,
    fetchCaps,
    createCaps,
    updateCaps,
    deleteCaps,
    fetchTimezones,
} from "../../../components/caps/service";
import { fetchFilterData2 } from "@/components/advertisers/service";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
import axios from "axios";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import EditComponent from "./EditComponent.vue";
import CreateComponent from "./CreateComponent.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import TimeZoneCom from "@/components/global/autoComplete/timeZoneCom.vue";
const Route = useRoute();
const store = useStore();
const apiUrl = computed(() => process.env.VUE_APP_API_URL);
const pageSize = ref(10);
const page = ref(1);
function handlePageChange(value) {
    page.value = value;
}
function handlePageSizeChange(value) {
    pageSize.value = value;
}
const allColumns = ref([
    {
        field: "interval",
        title: "Interval",
        visible: true,
        sortable: true,
    },
    {
        field: "type",
        title: "Type",
        visible: true,
        sortable: true,
    },
    {
        field: "filled",
        title: "Filled",
        visible: true,
        sortable: true,
    },
    {
        field: "countries",
        title: "Countries",
        visible: true,
        sortable: true,
    },
    {
        field: "advertisers",
        title: "Advertiser",
        visible: true,
        sortable: true,
    },
    {
        field: "affiliates",
        title: "Publisher",
        visible: true,
        sortable: true,
    },
    {
        field: "reset_time",
        title: "Reset At",
        visible: true,
        sortable: true,
    },
    {
        field: "timezone",
        title: "Timezone",
        visible: true,
        sortable: true,
    },
    {
        field: "actions",
        title: "Actions",
        visible: true,
        sortable: false,
    },
]);
// parameter
const parameterLabels = ref([]);
const filterData = ref([]);
const parameterValues = computed(() => {
    if (filterData.value && parameterLabel.value?.name) {
        return filterData.value[parameterLabel.value["name"]]["data"];
    } else return [];
});
const parameterLabel = ref("");
const parameterValue = ref("");
const selectedParameters = ref([]);
function addParameter() {
    // user cannot select the same parameter twice, so remove the selected from the paramLabels
    parameterLabels.value = parameterLabels.value.filter(
        (value) => value.id !== parameterLabel.value.id,
    );
    selectedParameters.value.push({
        label: parameterLabel.value,
        value: parameterValue.value,
    });
    parameterLabel.value = "";
    parameterValue.value = "";
}
function removeParameter(index) {
    parameterLabels.value.push(selectedParameters.value[index].label);
    selectedParameters.value.splice(index, 1);
}
// ending
const loading = ref(false);
const Props = defineProps({
    cap: {
        type: [Object, Array],
        default: () => [],
    },
});
const dialog = ref(false);
const capData = ref([]);
watch(
    () => Props.cap,
    (newVal) => {
        capData.value = newVal;
    },
    { immediate: true },
);
const Emit = defineEmits(["reloadApi"]);
const columns = ref([
    {
        field: "interval",
        title: "Interval",
        visible: true,
        sortable: true,
    },
    {
        field: "type",
        title: "Type",
        visible: true,
        sortable: true,
    },
    {
        field: "filled",
        title: "Filled",
        visible: true,
        sortable: true,
    },
    {
        field: "countries",
        title: "Countries",
        visible: true,
        sortable: true,
    },
    {
        field: "advertisers",
        title: "Advertiser",
        visible: true,
        sortable: true,
    },
    {
        field: "affiliates",
        title: "Publisher",
        visible: true,
        sortable: true,
    },
    {
        field: "reset_time",
        title: "Reset At",
        visible: true,
        sortable: true,
    },
    {
        field: "timezone",
        title: "Timezone",
        visible: true,
        sortable: true,
    },
    {
        field: "actions",
        title: "Actions",
        visible: true,
        sortable: false,
    },
]);
// const editRecord = (record) => {
//     console.log("Edit Record:", record);
//     // form.value.country_id = record.country.id;
//     // form.value.amount = record.amount;
//     // form.value.revenue_id = record.id;
//     dialog.value = true;
// };
const addRecord = async () => {
    dialog.value = true;
    nextTick(() => {
        editId.value = null;
        selectedAdvertisers.value = [Number(Route.params.id)];
        selectedParameters.value = [];
        interval.value = "daily";
        capType.value = "lead";
        resetAt.value = "00:00";
        cap.value = "0";
        // timezone.value = Number(241);
        selectedCountry.value = [];
        selectedPublishers.value = [];
        parameterLabels.value = [];
        parameterValue.value = "";
        // capRef.value.resetValidation();
    });
};
const submitForm = async () => {
    // console.log("Form Data:", form.value);
    dialog.value = false;
};
const params = reactive({
    current_page: 1,
    pagesize: 100,
});
// Interval	Type Filled	Countries	Advertiser	Publisher	Reset At	Timezone	Action
const rows = ref([]);
const dropdownOpen = ref(false);
const dropdown = ref(null);
const interval = ref("");
const capType = ref("");
const resetAt = ref("");
const editId = ref(null);
const resetAtMenu = ref(false);
const timezone = ref("");
const timezones = ref([]);
const findTimezone = (id) => {
    const timezone = timezones.value.find((timezone) => timezone.id == id);
    return timezone ? timezone.name : id;
};
const cap = ref(0);
const countries = computed(() => store.getters.getCountries);
const selectedCountry = ref([]);
const publishers = ref([]);
const advertisers = ref([]);
const selectedAdvertisers = ref([]);
const progress = ref(0);
function validateTime(time) {
    if (!time) {
        return false;
    }
    const timeParts = time.split(":");
    if (timeParts.length !== 2) {
        return false;
    }
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    if (timeParts[0].length !== 2 || timeParts[1].length !== 2) {
        return false;
    }
    if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
        return true;
    }
    return false;
}
function calculateProgress(filled, cap) {
    if (cap === 0) {
        return 100;
    }
    return Math.round((filled / cap) * 100);
}
function getCountry(id) {
    return countries.value.find((obj) => obj?.id === id);
}
function toggleDropdown() {
    dropdownOpen.value = !dropdownOpen.value;
}
// const editModal = ref(null);
// const createModal = ref(null);
async function editCapsRow(id) {
    //     {
    //     "id": 97,
    //     "cap": 2,
    //     "filled": 0,
    //     "type": "Lead",
    //     "interval": "Interval",
    //     "reset_time": "10:22:00",
    //     "advertiser_id": null,
    //     "timezone_id": 4,
    //     "country_ids": null,
    //     "created_at": "2024-10-13T22:03:57.000000Z",
    //     "updated_at": "2024-10-13T22:03:57.000000Z",
    //     "is_global": 0,
    //     "affiliate_id": null,
    //     "countries": [
    //         {
    //             "id": 2,
    //             "name": "Albania",
    //             "country_icon": "https://ipdata.co/flags/al.png",
    //             "code": "AL",
    //             "phoneCode": "355",
    //             "created_at": null,
    //             "updated_at": null,
    //             "pivot": {
    //                 "cap_id": 97,
    //                 "country_id": 2
    //             }
    //         },
    //         {
    //             "id": 5,
    //             "name": "Andorra",
    //             "country_icon": "https://ipdata.co/flags/ad.png",
    //             "code": "AD",
    //             "phoneCode": "376",
    //             "created_at": null,
    //             "updated_at": null,
    //             "pivot": {
    //                 "cap_id": 97,
    //                 "country_id": 5
    //             }
    //         }
    //     ],
    //     "advertisers": [
    //         {
    //             "id": 3,
    //             "status": "active",
    //             "name": "INTEGRATION Advertiser",
    //             "display_name": "Test Advertiser 1",
    //             "user_id": 1,
    //             "created_at": "2024-01-20T17:17:43.000000Z",
    //             "updated_at": "2024-01-20T18:22:04.000000Z",
    //             "post_url": "https://api.kaprin.co/v1/dummy/leads",
    //             "get_url": "https://api.kaprin.co/v1/dummy/leads",
    //             "auth_type": "",
    //             "auth": "",
    //             "headers": "apikey",
    //             "post_payload": null,
    //             "parametter_payload": null,
    //             "res_payload": "{\"hit_id\": \"id\", \"autologin_url\": \"auto_login_url\"}",
    //             "date_format": null,
    //             "json_payload": "{\"ip\": \"\", \"email\": \"\", \"phone\": \"\", \"locale\": \"\", \"comment\": \"\", \"custom1\": \"\", \"custom2\": \"\", \"custom3\": \"\", \"custom4\": \"\", \"custom5\": \"\", \"areaCode\": \"\", \"lastName\": \"\", \"firstName\": \"\", \"offerName\": \"\", \"offerWebsite\": \"\"}",
    //             "payload_map": "{\"ip\": {\"map_key\": \"ip\", \"is_required\": true}, \"email\": {\"map_key\": \"email\", \"is_required\": true}, \"phone\": {\"map_key\": \"phone\", \"is_required\": true}, \"locale\": {\"map_key\": \"\", \"is_required\": false}, \"comment\": {\"map_key\": \"\", \"is_required\": false}, \"custom1\": {\"map_key\": \"\", \"is_required\": false}, \"custom2\": {\"map_key\": \"\", \"is_required\": false}, \"custom3\": {\"map_key\": \"\", \"is_required\": false}, \"custom4\": {\"map_key\": \"\", \"is_required\": false}, \"custom5\": {\"map_key\": \"\", \"is_required\": false}, \"areaCode\": {\"map_key\": \"\", \"is_required\": false}, \"lastName\": {\"map_key\": \"last_name\", \"is_required\": true}, \"firstName\": {\"map_key\": \"first_name\", \"is_required\": true}, \"offerName\": {\"map_key\": \"\", \"is_required\": false}, \"offerWebsite\": {\"map_key\": \"\", \"is_required\": false}}",
    //             "lead_id": null,
    //             "is_test_advertiser": 0,
    //             "is_accept_id": 0,
    //             "res_status": null,
    //             "res_status_payload": "{\"hit_id\": \"id\", \"status\": \"status\"}",
    //             "extra_payload": null,
    //             "content_type": null,
    //             "ftd_url": null,
    //             "ftd_status_payload": null,
    //             "success_payload": null,
    //             "dated_column": null,
    //             "date_duration": null,
    //             "extra_url": null,
    //             "token_payload": null,
    //             "status_success_res": null,
    //             "setting_defaults": null,
    //             "ftd_res_status_payload": null,
    //             "ftd_status_success_res": null,
    //             "token_res": null,
    //             "pivot": {
    //                 "cap_id": 97,
    //                 "advertiser_id": 3
    //             },
    //             "percentage": null
    //         },
    //         {
    //             "id": 15,
    //             "status": "active",
    //             "name": "Streetwise",
    //             "display_name": "Streetwise",
    //             "user_id": 1,
    //             "created_at": "2024-05-14T10:03:06.000000Z",
    //             "updated_at": "2024-05-14T10:03:06.000000Z",
    //             "post_url": "https://stwiapi.org/leads",
    //             "get_url": "https://stwiapi.org/affiliate_deposits/?token=FZCoZqqDqlSaKRfbPwyV&limit=5000",
    //             "auth_type": "API Key",
    //             "auth": "FZCoZqqDqlSaKRfbPwyV",
    //             "headers": "apikey",
    //             "post_payload": null,
    //             "parametter_payload": null,
    //             "res_payload": "{\"hit_id\": \"lead.hitid\", \"autologin_url\": \"extras.redirect.url\"}",
    //             "date_format": "Y-m-d",
    //             "json_payload": "{\"_ip\": \"\", \"email\": \"\", \"phone\": \"\", \"funnel\": \"\", \"aff_sub\": \"\", \"aff_sub_2\": \"\", \"last_name\": \"\", \"first_name\": \"\"}",
    //             "payload_map": "{\"_ip\": {\"map_key\": \"ip\", \"is_required\": false}, \"email\": {\"map_key\": \"email\", \"is_required\": true}, \"phone\": {\"map_key\": \"phone\", \"is_required\": true}, \"funnel\": {\"map_key\": \"funnel\", \"is_required\": false}, \"aff_sub\": {\"map_key\": \"affiliate_id\", \"is_required\": true}, \"password\": {\"map_key\": \"password\", \"is_required\": false}, \"aff_sub_2\": {\"map_key\": \"offer\", \"is_required\": true}, \"area_code\": {\"map_key\": \"\", \"is_required\": false}, \"last_name\": {\"map_key\": \"last_name\", \"is_required\": true}, \"first_name\": {\"map_key\": \"first_name\", \"is_required\": true}}",
    //             "lead_id": null,
    //             "is_test_advertiser": 0,
    //             "is_accept_id": 0,
    //             "res_status": null,
    //             "res_status_payload": "{\"hit_id\": \"hitid\", \"status\": \"sale_status\"}",
    //             "extra_payload": "{\"affid\": 211, \"api_token\": \"FZCoZqqDqlSaKRfbPwyV\"}",
    //             "content_type": null,
    //             "ftd_url": null,
    //             "ftd_status_payload": null,
    //             "success_payload": null,
    //             "dated_column": null,
    //             "date_duration": null,
    //             "extra_url": null,
    //             "token_payload": null,
    //             "status_success_res": null,
    //             "setting_defaults": null,
    //             "ftd_res_status_payload": null,
    //             "ftd_status_success_res": null,
    //             "token_res": null,
    //             "pivot": {
    //                 "cap_id": 97,
    //                 "advertiser_id": 15
    //             },
    //             "percentage": null
    //         }
    //     ],
    //     "affiliates": [
    //         {
    //             "id": 5,
    //             "name": "BLV",
    //             "status": "active",
    //             "api_token": "Dkn4qBSSy2fX5H7dxVQm",
    //             "autologin_interval": "days",
    //             "block_duplicate_leads": 0,
    //             "tracking_api_token": "vk9mpKQ9oitRBECXBkN6",
    //             "tracking_domain": null,
    //             "offer_url": null,
    //             "locate_leads_by": "system",
    //             "created_at": "2024-06-03T14:15:54.000000Z",
    //             "updated_at": "2024-06-05T14:33:02.000000Z",
    //             "deleted_at": null,
    //             "deleted_by": null,
    //             "pivot": {
    //                 "cap_id": 97,
    //                 "affiliate_id": 5
    //             }
    //         },
    //         {
    //             "id": 6,
    //             "name": "Dr Traffic",
    //             "status": "active",
    //             "api_token": "4mshzCcVJcZa86fdz1o7",
    //             "autologin_interval": "days",
    //             "block_duplicate_leads": 0,
    //             "tracking_api_token": "JDyD4eFJdAs0HQjjNcHy",
    //             "tracking_domain": null,
    //             "offer_url": null,
    //             "locate_leads_by": "system",
    //             "created_at": "2024-06-03T14:41:12.000000Z",
    //             "updated_at": "2024-06-05T14:33:10.000000Z",
    //             "deleted_at": null,
    //             "deleted_by": null,
    //             "pivot": {
    //                 "cap_id": 97,
    //                 "affiliate_id": 6
    //             }
    //         }
    //     ],
    //     "timezone": {
    //         "id": 4,
    //         "name": "Africa/Algiers",
    //         "offset": "+01:00",
    //         "diff_from_gtm": "UTC/GMT +01:00",
    //         "created_at": "2024-04-03T12:34:38.000000Z",
    //         "updated_at": "2024-04-03T12:34:38.000000Z"
    //     }
    // }
    console.log(
        "🚀 ~ file: DataTableComponent.vue:767 ~ editCapsRow ~ id:",
        id,
    );
    editId.value = await id.id;
    // selectedPublishers.value = id?.affiliate_id;
    selectedPublishers.value = id?.affiliates.map((affiliate) => affiliate.id);
    // selectedAdvertisers.value = id?.advertiser_id;
    timezone.value = id?.timezone_id;
    // country_ids is come null
    // selectedCountry.value = id?.country_ids;
    selectedCountry.value = id?.countries.map((country) => country.id);
    interval.value = id?.interval;
    capType.value = id?.type;
    // type.value = id?.type;
    resetAt.value =
        id?.reset_time?.split(":")[0] + ":" + id?.reset_time?.split(":")[1];
    cap.value = id?.cap;
    dialog.value = true;
}
async function createRow() {
    // fetch fill the autocomplete
    editId.value = null;
    selectedPublishers.value = [];
    selectedAdvertisers.value = [];
    selectedCountry.value = [];
    interval.value = "";
    capType.value = "";
    resetAt.value = null;
    timezone.value = "";
    cap.value = 0;
    // show modal
    dialog.value = true;
}
const capRef = ref(null);
async function saveCaps() {
    const { valid } = await capRef.value.validate();
    if (!valid) {
        return;
    }
    const data = {
        advertiser_ids: selectedAdvertisers.value,
        type: capType.value,
        interval: interval.value,
        cap: String(cap.value),
        timezone_id: timezone.value,
        country_ids: selectedCountry.value,
        affiliate_ids: selectedPublishers.value,
        reset_time: resetAt.value || "00:00",
        parameters: Object.fromEntries(
            selectedParameters.value.map((parameter) => [
                parameter.label.id,
                parameter.value.id,
            ]),
        ),
    };
    try {
        if (editId.value !== null) {
            // edit cap
            data.cap_id = editId.value;
            const response = await updateCaps(data);
            if (!response) {
                throw new Error("Failed to update");
            }
            Emit("reloadApi");
            Swal.fire({
                icon: "success",
                title: "Successfully updated",
                text: "Cap updated successfully",
            });
            // console.log("Edit Cap", response);
        } else {
            console.log("Create Cap payload", data);
            const response = await createCaps(data);
            if (!response) {
                throw new Error("Failed to create");
            }
            Emit("reloadApi");
            Swal.fire({
                icon: "success",
                title: "Successfully created",
                text: "Cap created successfully",
            });
            // console.log("Create Cap", response);
        }
    } catch (error) {
        console.error("Failed to save Cap:", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to save Cap. Please try again.",
        });
    } finally {
        dialog.value = false;
    }
}
function deleteCapsRow(id) {
    console.log("Reject Lead: " + id);
    Swal.fire({
        title: "Are you sure?",
        text: `You are sure about to delete`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                // Make the POST request with axios
                const data = await deleteCaps(id);
                Emit("reloadApi");
                if (!data) {
                    throw new Error("Failed to delete");
                }
                Swal.fire({
                    icon: "success",
                    title: "Successfully deleted",
                    text: data.message,
                });
            } catch (error) {
                // Handle error and display an error alert
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to delete. Please try again.",
                });
                console.error("Failed to delete:", error);
            }
        }
    });
}
function toggleColumnVisibility(field) {
    const column = allColumns.value.find((col) => col.field === field);
    if (column) {
        column.visible = !column.visible;
    }
}
const visibleColumns = computed(() => {
    return allColumns.value.filter((col) => col.visible);
});
function handleClickOutside(event) {
    if (dropdown.value && !dropdown.value.contains(event.target)) {
        dropdownOpen.value = false;
    }
}
const selectedPublishers = ref([]);
onMounted(async () => {
    // rows.value = (await fetchCaps()) || [];
    filterData.value = await fetchFilterData2();
    for (const parameter in filterData.value) {
        parameterLabels.value.push({
            name: parameter,
            id: filterData.value[parameter].id,
        });
        parameterValues.value = [
            ...parameterValues.value,
            ...filterData.value[parameter].data,
        ];
    }
    timezones.value = (await fetchTimezones()) || [];
    publishers.value = (await fetchFilterData()).publishers || [];
    advertisers.value = (await fetchFilterData()).advertisers || [];
    // selectedPublishers.value.push(Number(Route?.params?.id));
    if (selectedAdvertisers.value) {
        selectedAdvertisers.value = [Number(Route.params.id)];
    }
    document.addEventListener("click", handleClickOutside);
});
onBeforeUnmount(() => {
    document.removeEventListener("click", handleClickOutside);
});
</script>
<style scoped>
/* .table-container {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    } */
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
    padding: 10px;
    max-height: 400px;
    overflow: scroll;
}
.dropdown-content label {
    display: block;
    padding: 5px 0;
}
.dropdown-content label:hover {
    background-color: #f1f1f1;
}
.img-flags {
    width: 15px;
    height: 15px;
}
</style>
