import axios from "axios";

export const deleteProgressLogsRequest = async (data) => {
    console.log("Payload:", data);
    try {
        const url = `/api/progress-logs`;
        const response = await axios.delete(url, { data });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const progressLogsCountRequest = async () => {
    try {
        const url = `/api/progress-logs-count`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchCountries = async () => {
    try {
        const url = `/api/countries`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const fetchFilterData = async () => {
    try {
        const url = `/api/get-filter-data`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchLeadDetails = async (payload) => {
    console.log("Payload:", payload);
    try {
        const url = `/api/lead-details`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchStatusHistory = async (payload) => {
    console.log("Payload:", payload);
    try {
        const url = `/api/lead-details/status-history`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchLeadHistory = async (payload) => {
    console.log("Payload:", payload);
    try {
        const url = `/api/lead-details/lead-history`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const requestStatusOverride = async (payload) => {
    console.log("Payload:", payload);
    try {
        const url = `/api/lead-details/status-override`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const updateLeadDetails = async (payload) => {
    console.log("Payload:", payload);
    try {
        const url = `/api/lead-details/update`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const requestMarkAsTestLeads = async (payload) => {
    console.log("Payload:", payload);
    try {
        const url = `/api/lead-details/mark-test-lead`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchProgressLogs = async () => {
    try {
        const url = `/api/progress-logs`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const requestManageSelection = async (payload) => {
    console.log("Payload:", payload);
    try {
        const url = `/api/lead-details/inject`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const requestFireFTD = async (payload) => {
    console.log("Payload:", payload);
    try {
        const url = `/api/lead-details/fire-fake-ftd`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const fetchDistributionById = async (id) => {
    console.log("payload:", id);

    try {
        const url = `/api/distributions/${id}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return {};
    }
};
