<template>
    <div>
        <v-dialog v-model="dialog" max-width="500px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline">{{
                        form.revenue_id ? "Edit Revenue" : "Create Revenue"
                    }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <!-- <v-row> -->
                        <v-form ref="revenueFormRef">
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <!-- {{ form }} -->
                                    <v-combobox
                                        label="Country Name"
                                        variant="outlined"
                                        v-model="form.country_id"
                                        :items="countries"
                                        item-value="id"
                                        :return-object="false"
                                        item-title="name"
                                        :rules="[
                                            (v) => !!v || 'Country is required',
                                        ]"
                                    >
                                    </v-combobox>
                                </div>
                                <div class="col-md-6">
                                    <!-- 34.00 -->
                                    <v-text-field
                                        :required="true"
                                        v-model="form.amount"
                                        label="Amount"
                                        variant="outlined"
                                        type="number"
                                        :rules="[
                                            (v) => !!v || 'Amount is required',
                                            (v) =>
                                                /^\d+(\.\d{1,2})?$/.test(v) ||
                                                /^\d+$/.test(v) ||
                                                'Amount must be a number',
                                            (v) =>
                                                v > 0 ||
                                                'Amount must be greater than 0',
                                        ]"
                                    ></v-text-field>
                                </div>
                            </div>
                        </v-form>
                        <!-- </v-row> -->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn
                        @click="submitForm"
                        :loading="loading"
                        color="primary"
                    >
                        submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- {{ revenueData }} -->
        <div class="statusDiv mb-4">
            <StandardBtn type="create" @click="addRecord" />
        </div>
        <Vue3Datatable
            @pageChange="handlePageChange"
            @pageSizeChange="handlePageSizeChange"
            :paginationInfo="
                'Showing {0} to {1} of {2} entries' +
                ` | Page ${page} of ${Math.ceil(revenueData.length / pageSize)}`
            "
            :stickyHeader="true"
            :columns="cols"
            :rows="revenueData"
            :sortable="true"
            :loading="loading"
            skin="bh-table-bordered bh-table-responsive"
        >
            <template #country="{ value }">
                <div v-if="value.country">
                    <CountryInfo :id="value.country?.id" />
                </div>
            </template>
            <template #amount="{ value }">
                {{ $formatCurrency(value.amount) }}
            </template>
            <template #actions="{ value }">
                <div class="d-flex gap-2">
                    <StandardBtn type="edit" @click="editRecord(value)" />
                    <StandardBtn type="delete" @click="deleteLeads(value.id)" />
                </div>
            </template>
        </Vue3Datatable>
    </div>
</template>
<script setup>
import Vue3Datatable from "@bhplugin/vue3-datatable";
import Swal from "sweetalert2";
import { ref, computed, defineEmits, defineProps, watch, nextTick } from "vue";
import {
    createAdvertiserRevenue,
    updateAdvertiserRevenue,
    deleteAdvertiserRevenue,
} from "@/components/advertisers/service.js";
import { useRoute } from "vue-router";
const Route = useRoute();
import { useStore } from "vuex";
const pageSize = ref(10);
const page = ref(1);
function handlePageChange(value) {
    page.value = value;
}
function handlePageSizeChange(value) {
    pageSize.value = value;
}
const cols = ref([
    // { field: "id", title: "ID", width: "90px", filter: false },
    { field: "amount", title: "Amount" },
    { field: "country", title: "Country" },
    // { field: "created_at", title: "Created at" },
    { field: "actions", title: "Actions", width: "150px", filter: false },
]);
const Emit = defineEmits(["reloadApi"]);
const Props = defineProps({
    revenue: {
        type: [Object, Array],
        required: true,
        default: () => [],
    },
});
const revenueData = ref([]);
watch(
    () => Props.revenue,
    (newVal) => {
        revenueData.value = newVal;
    },
    { immediate: true },
);
const store = useStore();
const revenueFormRef = ref(null);
const form = ref({
    revenue_id: null,
    country_id: "",
    amount: "",
});
const dialog = ref(false);
const countries = computed(() => store.getters.getCountries);
// Method to handle form submission
const loading = ref(false);
async function submitForm() {
    const { valid } = await revenueFormRef.value.validate();
    if (!valid) {
        return;
    }
    loading.value = true;
    form.value.advertiser_id = Route.params.id;
    if (form.value.revenue_id) {
        const resp = await updateAdvertiserRevenue(form.value);
        if (resp) {
            Emit("reloadApi");
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Record updated successfully.",
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to update. Please try again.",
            });
        }
    } else {
        delete form.value.revenue_id;
        // we do like this because of api need this data otherwise it give server error
        const countryId = form.value.country_id.toString();
        form.value.country_id = countryId;
        const Amount = form.value.amount;
        form.value.amount = Number(Amount);
        const resp = await createAdvertiserRevenue(form.value);
        if (resp) {
            Emit("reloadApi");
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Record created successfully.",
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to create. Please try again.",
            });
        }
    }
    loading.value = false;
    dialog.value = false;
}
function deleteLeads(id) {
    Swal.fire({
        title: "Are you sure?",
        text: `You are sure about to delete`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                // Call delete API
                const data = await deleteAdvertiserRevenue({ revenue_id: id });
                // Display success alert
                if (data) {
                    Emit("reloadApi");
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Record deleted successfully.",
                    });
                    revenueData.value = revenueData.value.filter(
                        (row) => row.id !== id,
                    );
                } else {
                    throw new Error("Failed to delete");
                }
            } catch (error) {
                // Handle error and display an error alert
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to delete. Please try again.",
                });
                console.error("Failed to delete:", error);
            }
        }
    });
}
const editRecord = (record) => {
    console.log("Edit Record:", record);
    form.value.country_id = record.country?.id;
    form.value.amount = record.amount;
    form.value.revenue_id = record.id;
    dialog.value = true;
};
const addRecord = async () => {
    // we reset revenue_id like this because this revenue_id not use in v-form
    form.value.revenue_id = null;
    dialog.value = true;
    nextTick(() => {
        revenueFormRef.value.reset(); // reset form
        // revenueFormRef.value.resetValidation(); // reset validation only not form
    });
};
</script>
