<template>
    <div>
        <v-dialog v-model="dialog" max-width="500px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline"
                        >{{ form.id ? "Edit" : "Create" }} Override
                        Publisher</span
                    >
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <div class="col-md-12">
                                <!-- {{ form }} -->
                                <v-combobox
                                    label="Advertiser Name"
                                    :disabled="true"
                                    variant="outlined"
                                    v-model="advertiser_name"
                                    item-value="id"
                                    :return-object="false"
                                    item-title="name"
                                >
                                </v-combobox></div
                        ></v-row>
                        <v-form ref="overrideRef">
                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <!-- {{ form }} -->
                                    <v-combobox
                                        label="Country Name"
                                        variant="outlined"
                                        v-model="form.country_id"
                                        :items="countries"
                                        item-value="id"
                                        :return-object="false"
                                        item-title="name"
                                        :rules="[
                                            (v) => !!v || 'Country is required',
                                        ]"
                                    >
                                    </v-combobox>
                                </div>
                                <div class="col-md-12">
                                    <v-combobox
                                        label="Publisher Name"
                                        variant="outlined"
                                        v-model="form.affiliate_id"
                                        :items="publishers"
                                        item-value="id"
                                        :return-object="false"
                                        item-title="name"
                                    >
                                        <!-- :rules="[
                                            (v) =>
                                                !!v || 'Publisher is required',
                                        ]" -->
                                    </v-combobox>
                                    <v-text-field
                                        v-model="form.sources"
                                        label="Sources"
                                        variant="outlined"
                                        type="text"
                                        :rules="[
                                            (v) =>
                                                !v ||
                                                v.length <= 255 ||
                                                'Max 255 characters are allowed',
                                        ]"
                                    ></v-text-field>
                                </div>
                                <div class="col-md-12">
                                    <v-combobox
                                        variant="outlined"
                                        v-model="form.override_values"
                                        label="Override Values"
                                        clearable
                                        multiple
                                        :rules="[
                                            (v) =>
                                                v?.length > 0 ||
                                                'Override is required',
                                        ]"
                                    >
                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <v-chip
                                                small
                                                @click="
                                                    form.override_values.splice(
                                                        index,
                                                        1,
                                                    )
                                                "
                                                close
                                                class="p-2"
                                            >
                                                {{ item.props.title }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </div>
                            </div>
                        </v-form>
                        <!-- </v-row> -->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn
                        @click="submitForm"
                        :loading="loading"
                        color="primary"
                    >
                        submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- {{ overrideData }} -->
        <div class="statusDiv mb-4">
            <StandardBtn type="create" @click="addRecord" />
        </div>
        <Vue3Datatable
            @pageChange="handlePageChange"
            @pageSizeChange="handlePageSizeChange"
            :paginationInfo="
                'Showing {0} to {1} of {2} entries' +
                ` | Page ${page} of ${Math.ceil(overrideData.length / pageSize)}`
            "
            :stickyHeader="true"
            :key="reloadComp"
            :columns="cols"
            :rows="overrideData"
            :sortable="true"
            :loading="loading"
            skin="bh-table-bordered bh-table-responsive"
        >
            <!-- Country -->
            <template #country_id="{ value }">
                <CountryInfo :id="value.country_id" />
            </template>
            <!-- affiliate_id -->
            <template #affiliate_id="{ value }">
                <span>{{ getPublishers(value.affiliate_id) }}</span>
            </template>
            <!-- override_values -->
            <template #override_values="{ value }">
                <div>
                    <span
                        class="mr-1"
                        v-for="(item, index) in value?.override_values"
                        :key="item"
                    >
                        {{ item
                        }}<span v-if="index < value.override_values?.length - 1"
                            >,</span
                        >
                    </span>
                </div>
            </template>
            <template #actions="{ value }">
                <div class="d-flex gap-2">
                    <StandardBtn type="edit" @click="editRecord(value)" />
                    <StandardBtn type="delete" @click="deleteLeads(value.id)" />
                </div>
            </template>
        </Vue3Datatable>
    </div>
</template>
<script setup>
import Vue3Datatable from "@bhplugin/vue3-datatable";
import Swal from "sweetalert2";
import {
    ref,
    computed,
    defineProps,
    watch,
    nextTick,
    onMounted,
    defineEmits,
} from "vue";
import {
    // publicr = 'affiliate'
    createPublisherOverride,
    updatePublisherOverride,
    deletePublisherOverride,
    fetchFilterData2,
} from "@/components/advertisers/service.js";
import { useRoute } from "vue-router";
const Route = useRoute();
import { useStore } from "vuex";
const pageSize = ref(10);
const page = ref(1);
function handlePageChange(value) {
    page.value = value;
}
function handlePageSizeChange(value) {
    pageSize.value = value;
}
const cols = ref([
    { field: "id", title: "ID", width: "90px", filter: false },
    { field: "country_id", title: "Country" },
    { field: "affiliate_id", title: "affiliate name" },
    { field: "override_values", title: "override values" },
    { field: "actions", title: "Actions", width: "150px", filter: false },
]);
const Emit = defineEmits(["reloadApi"]);
const reloadComp = ref(false);
const publishers = ref([]);
const getPublishers = (id) => {
    const publisher = publishers.value.find((p) => p.id == id);
    return publisher ? publisher.name : id;
};
onMounted(async () => {
    const filterData = await fetchFilterData2();
    publishers.value = await filterData["Publishers"]["data"];
});
const Props = defineProps({
    override: {
        type: [Object, Array],
        required: true,
        default: () => [],
    },
    advertiserName: {
        type: String,
        required: true,
    },
});
const overrideData = ref([]);
watch(
    () => Props.override,
    (newVal) => {
        overrideData.value = newVal;
    },
    { immediate: true },
);
const advertiser_name = ref("");
watch(
    () => Props.advertiserName,
    (newVal) => {
        advertiser_name.value = newVal;
    },
    { immediate: true },
);
const store = useStore();
const overrideRef = ref(null);
// const overrideSearch = ref("");
const form = ref({
    id: null,
    country_id: null,
    affiliate_id: null,
    sources: "",
    override_values: [],
});
const dialog = ref(false);
const countries = computed(() => store.getters.getCountries);
// Method to handle form submission
const loading = ref(false);
async function submitForm() {
    const { valid } = await overrideRef.value.validate();
    if (!valid) {
        return;
    }
    loading.value = true;
    form.value.advertiser_id = Route.params.id;
    // eslint-disable-next-line no-unreachable
    if (form.value.id) {
        const resp = await updatePublisherOverride(form.value.id, form.value);
        if (resp) {
            const index = overrideData.value.findIndex(
                (row) => row.id == form.value.id,
            );
            overrideData.value[index] = resp;
            reloadComp.value = !reloadComp.value;
        }
    } else {
        delete form.value.id;
        const resp = await createPublisherOverride(form.value);
        if (resp) {
            overrideData.value.push(resp);
            reloadComp.value = !reloadComp.value;
        }
    }
    loading.value = false;
    dialog.value = false;
}
function deleteLeads(id) {
    Swal.fire({
        title: "Are you sure?",
        text: `You are sure about to delete`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                // Call delete API
                const data = await deletePublisherOverride(id);
                // Display success alert
                if (data) {
                    Emit("reloadApi");
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Record deleted successfully.",
                    });
                    overrideData.value = overrideData.value.filter(
                        (row) => row.id !== id,
                    );
                }
            } catch (error) {
                // Handle error and display an error alert
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to delete. Please try again.",
                });
                console.error("Failed to delete:", error);
            }
        }
    });
}
const editRecord = (record) => {
    // eslint-disable-next-line no-unreachable
    form.value.id = record.id;
    form.value.country_id = record.country_id;
    form.value.affiliate_id = record.affiliate_id;
    form.value.sources = record?.sources;
    form.value.override_values = record.override_values;
    dialog.value = true;
};
const addRecord = async () => {
    form.value.id = null;
    dialog.value = true;
    nextTick(() => {
        overrideRef.value.reset(); // reset form
        // overrideRef.value.resetValidation(); // reset validation only not form
    });
};
</script>
