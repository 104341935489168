<template>
    <div class="">
        <div class="dropdown d-flex gap-2 justify-end" ref="dropdown">
            <!-- <button
                class="btn-shadow btn btn-dark btn-sm rounded dense mb-4"
                @click="toggleDropdown"
            >
                Columns
            </button> 
            <div
                v-show="dropdownOpen"
                class="dropdown-content"
                style="margin-top: 0px; z-index: 11"
            >
                <label v-for="col in allColumns" :key="col.field">
                    <input
                        type="checkbox"
                        :checked="col.visible"
                        @change="() => toggleColumnVisibility(col)"
                    />
                    {{ col.title }}
                </label>
            </div> -->
            <div class="mb-2">
                <StandardBtn
                    type="create"
                    @click="
                        openCreateRoleDialog({
                            id: null,
                            data: null,
                            create: true,
                        })
                    "
                >
                </StandardBtn>
            </div>
        </div>
        <v-data-table
            :fixed-header="true"
            :fixed-footer="true"
            :sticky="true"
            height="70vh"
            color="#f00"
            :headers="visibleColumns"
            :items="rows"
            :loading="loading"
            :items-per-page-options="[10, 15, 30, 50, 100, 200, 500, 1000]"
            v-model="slectedTableRows"
            class="elevation-1"
            v-model:items-per-page="pageSize"
            :hide-default-footer="true"
            @update:itemsPerPage="handlePageSizeChange"
            @update:page="handlePageChange"
        >
            <template v-slot:top="{ pagination, options, updateOptions }">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="ml-5">{{ paginationInfo }}</div>
                    <v-data-table-footer
                        :pagination="pagination"
                        :options="options"
                        @update:options="updateOptions"
                        items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                </div>
            </template>
            <template #[`item.name`]="{ item }">
                {{ !item.name ? "-" : item.name }}
            </template>
            <template #[`item.permissions`]="{ item, index }">
                <div
                    class="d-flex flex-wrap gap-2 pa-2"
                    v-if="item.permissions.length > 0"
                >
                    <div
                        class="d-flex flex-wrap gap-2"
                        v-if="!showFull['permissions'][index]"
                    >
                        <template
                            v-for="permission in formatPermissions(
                                item.permissions?.slice(0, 3),
                            )"
                            :key="permission"
                        >
                            <v-chip class="mx-05" label>
                                {{ permission }}
                            </v-chip>
                        </template>
                        <span
                            v-if="item.permissions?.length > 3"
                            class="text-decoration-underline pa-0 cursor-pointer"
                            @click.stop="showFull['permissions'][index] = true"
                        >
                            Show more
                        </span>
                    </div>
                    <div class="d-flex flex-wrap gap-2" v-else>
                        <template
                            v-for="permission in formatPermissions(
                                item.permissions,
                            )"
                            :key="permission"
                        >
                            <v-chip class="mx-05" label>
                                {{ permission }}
                            </v-chip>
                        </template>
                        <span
                            class="text-decoration-underline pa-0 cursor-pointer"
                            @click.stop="showFull['permissions'][index] = false"
                        >
                            Show less
                        </span>
                    </div>
                </div>
                <span v-else class="ma-1"> - </span>
            </template>
            <template #[`item.actions`]="{ item }">
                <div class="d-flex gap-2">
                    <StandardBtn
                        type="copy"
                        @click="
                            openCreateRoleDialog({
                                id: item.id,
                                data: item,
                                create: true,
                            })
                        "
                    >
                    </StandardBtn>
                    <StandardBtn
                        type="edit"
                        @click="
                            openCreateRoleDialog({
                                id: item.id,
                                data: item,
                                create: false,
                            })
                        "
                    >
                    </StandardBtn>
                    <StandardBtn
                        type="delete"
                        @click="openDeleteRoleDialog(item.id)"
                    ></StandardBtn>
                </div>
            </template>
        </v-data-table>
    </div>
    <CreateRoleModal
        v-if="createRoleDialog"
        @close="closeCreateRoleDialog"
        :roleId="roleUpdateId"
        :roleData="roleData"
    />
    <v-dialog v-model="deleteRoleDialog" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">Confirm Action</span>
            </v-card-title>
            <v-card-text>
                Are you sure you want to delete the selected role?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteRoleDialog = false"
                    >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteRole"
                    >Yes, Confirm</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { getRoles, getPermissions, deleteRoleById } from "./service";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import CreateRoleModal from "./CreateRoleModal.vue";
export default {
    components: {
        CreateRoleModal,
    },
    setup() {
        const deleteRoleDialog = ref(false);
        const deleteRoleId = ref(null);

        const roleData = ref(null);
        const roleUpdateId = ref(null);

        const createRoleDialog = ref(false);
        const dropdownOpen = ref(false);
        const dropdown = ref(null);
        const rows = ref([]);
        const permissions = ref([]);
        const allColumns = ref([
            {
                value: "id",
                title: "ID",
                visible: true,
                sortable: true,
            },
            {
                value: "name",
                title: "Name",
                visible: true,
                sortable: true,
            },
            {
                value: "permissions",
                title: "Permissions",
                visible: true,
                sortable: true,
            },
            {
                value: "actions",
                title: "Actions",
                visible: true,
                sortable: true,
            },
        ]);

        const showFull = ref({
            permissions: [],
        });

        const pageSize = ref(100);
        const page = ref(1);
        function handlePageChange(value) {
            page.value = value;
        }
        function handlePageSizeChange(value) {
            pageSize.value = value;
        }
        const paginationInfo = computed(() => {
            if (Math.ceil(rows.value.length / pageSize.value) === 0) {
                return "";
            } else if (pageSize.value === -1) {
                return `Page 1 of 1`;
            } else {
                return `Page ${page.value} of ${Math.ceil(rows.value.length / pageSize.value)}`;
            }
        });
        function toggleColumnVisibility(field) {
            const column = allColumns.value.find(
                (col) => col.value === field.value,
            );
            if (column) {
                column.visible = !column.visible;
            }
        }
        function formatPermissions(value) {
            const permissionsArray = [].concat(
                ...Object.values(this.permissions),
            );
            return permissionsArray
                .filter((p) => value.includes(p.id))
                .map((p) => p.name);
        }
        const visibleColumns = computed(() => {
            return allColumns.value.filter((col) => col.visible);
        });
        function toggleDropdown() {
            dropdownOpen.value = !dropdownOpen.value;
        }
        function handleClickOutside(event) {
            if (dropdown.value && !dropdown.value.contains(event.target)) {
                dropdownOpen.value = false;
            }
        }
        async function openCreateRoleDialog({ id, data, create }) {
            // console.log(id, page, create);
            roleData.value = null;
            roleUpdateId.value = id;
            if (create) {
                roleUpdateId.value = null;
            }
            if (!id) {
                createRoleDialog.value = true;
                return;
            }
            // fetch the id data
            roleData.value = data;
            createRoleDialog.value = true;
        }
        async function closeCreateRoleDialog() {
            createRoleDialog.value = false;
            rows.value = (await getRoles()) || [];
            permissions.value = (await getPermissions()) || [];
            showFull.value = {
                permissions: rows.value.map(() => false),
            };
        }
        function openDeleteRoleDialog(id) {
            deleteRoleDialog.value = true;
            deleteRoleId.value = id;
        }
        async function deleteRole() {
            try {
                await deleteRoleById(deleteRoleId.value);
                deleteRoleDialog.value = false;
                Swal.fire({
                    title: "Success",
                    text: "Role deleted successfully",
                    icon: "success",
                });
                rows.value = (await getRoles()) || [];
            } catch (error) {
                deleteRoleDialog.value = false;
                Swal.fire({
                    title: "Error",
                    text: error.message,
                    icon: "error",
                });
            }
        }
        onMounted(async () => {
            document.addEventListener("click", handleClickOutside);
            rows.value = (await getRoles()) || [];
            showFull.value = {
                permissions: rows.value.map(() => false),
            };
            permissions.value = (await getPermissions()) || [];
        });
        onBeforeUnmount(() => {
            document.removeEventListener("click", handleClickOutside);
        });

        return {
            allColumns,
            visibleColumns,
            toggleColumnVisibility,
            dropdownOpen,
            dropdown,
            toggleDropdown,
            rows,
            permissions,
            createRoleDialog,
            openCreateRoleDialog,
            closeCreateRoleDialog,
            deleteRoleDialog,
            openDeleteRoleDialog,
            deleteRole,
            formatPermissions,
            showFull,
            roleData,
            roleUpdateId,
            pageSize,
            page,
            handlePageChange,
            handlePageSizeChange,
            paginationInfo,
        };
    },
};
</script>

<style scoped>
::v-deep thead > tr,
::v-deep th {
    background: black !important;
    color: white;
}

::v-deep th:hover {
    color: rgba(255, 255, 255, 0.8) !important;
}
</style>
