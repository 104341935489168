<template>
    <div>
        <!-- Dropdown for column visibility -->
        <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="dropdown" ref="dropdown">
                <div
                    v-show="dropdownOpen"
                    class="dropdown-content"
                    style="margin-top: 0px"
                >
                    <label v-for="col in allColumns" :key="col.field">
                        <input
                            type="checkbox"
                            :checked="col.visible"
                            @change="() => toggleColumnVisibility(col.field)"
                        />
                        {{ col.title }}
                    </label>
                </div>
            </div>
            <StandardBtn type="create" @click="newUser" />
        </div>
        <!-- DataTable Component -->
        <div class="bh-table-responsive table-container mb-5 mt-2">
            <v-col cols="12" sm="6" md="4" lg="3" class="mb-0 pb-0">
                <v-text-field
                    label="Search"
                    v-model="searchTable"
                    hide-details
                    variant="outlined"
                    clearable
                    density="compact"
                />
            </v-col>
            <Vue3Datatable
                @pageChange="handlePageChange"
                @pageSizeChange="handlePageSizeChange"
                :paginationInfo="
                    'Showing {0} to {1} of {2} entries' +
                    ` | Page ${page} of ${Math.ceil(rows.length / pageSize)}`
                "
                :search="searchTable"
                height="65vh"
                :stickyHeader="true"
                :columns="visibleColumns"
                :rows="rows"
                :sortable="true"
                :loading="loading"
                skin="bh-table-bordered bh-table-responsive"
            >
                <template #actions="{ value }">
                    <div class="d-flex gap-2">
                        <StandardBtn type="edit" @click="editLead(value)" />
                        <StandardBtn
                            type="delete"
                            @click="deleteLeads(value.id)"
                        />
                    </div>
                </template>
            </Vue3Datatable>
        </div>
        <!-- Edit Modal -->
        <!-- <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
            ref="editModal"
        >
            <EditComponent :editId="editId" />
        </div> -->
        <!-- <div
            class="modal fade"
            id="createModal"
            tabindex="-1"
            aria-labelledby="creatModalLabel"
            aria-hidden="true"
            ref="createModal"
        >
            <CreateComponent />
        </div> -->
        <v-dialog
            v-model="dialog"
            persistent
            transition="dialog-bottom-transition"
            fullscreen
        >
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        <span class="headline">{{
                            editId ? "Edit User" : "Create User"
                        }}</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-list lines="two" subheader>
                    <v-list-subheader class="text-uppercase">
                        <v-icon>mdi-cog</v-icon>
                        General Information</v-list-subheader
                    >
                    <v-list-item>
                        <v-form ref="userFormRef" class="pt-4">
                            <div class="row mb-3">
                                <div class="col-md-4">
                                    <v-text-field
                                        :required="true"
                                        v-model="form.name"
                                        label="Name"
                                        variant="outlined"
                                        :rules="[
                                            (v) => !!v || 'Name is required',
                                        ]"
                                    ></v-text-field>
                                </div>
                                <div class="col-md-4">
                                    <v-text-field
                                        :required="true"
                                        v-model="form.email"
                                        label="Email"
                                        variant="outlined"
                                        :rules="[
                                            (v) => !!v || 'Email is required',
                                            (v) =>
                                                /.+@.+\..+/.test(v) ||
                                                'E-mail must be valid',
                                        ]"
                                    ></v-text-field>
                                </div>
                                <div class="col-md-4">
                                    <v-select
                                        v-model="form.level"
                                        label="Role"
                                        item-title="text"
                                        item-value="value"
                                        variant="outlined"
                                        :items="levelItems"
                                        :rules="[
                                            (v) => !!v || 'Role is required',
                                            (v) =>
                                                /^\d+$/.test(v) ||
                                                'Role must be a number',
                                        ]"
                                        :return-object="false"
                                    ></v-select>
                                </div>
                                <div class="col-md-4">
                                    <v-text-field
                                        :required="true"
                                        v-model="form.password"
                                        label="Password"
                                        variant="outlined"
                                        type="password"
                                    />
                                    <!-- :rules="[
                                        (v) => !!v || 'Password is required',
                                        (v) =>
                                            /^[A-Za-z0-9]+$/.test(v) ||
                                            'Password must be alphanumeric',
                                        (v) =>
                                            (v &&
                                                v.length >= 8 &&
                                                v.length <= 12) ||
                                            'Password must be between 8 and 12 characters',
                                        (v) =>
                                            /[A-Z]/.test(v) ||
                                            'Password must contain at least one uppercase letter',
                                    ]" -->
                                </div>
                                <div class="col-md-4">
                                    <v-text-field
                                        :required="true"
                                        v-model="form.confirmPassword"
                                        label="Confirm Password"
                                        variant="outlined"
                                        type="password"
                                    />
                                    <!-- :rules="[
                                        (v) =>
                                            !!v ||
                                            'Confirm password is required',
                                        (v) =>
                                            v === form.password ||
                                            'Passwords do not match',
                                    ]" -->
                                    <!-- <v-text-field
                                    :required="true"
                                    v-model="form.password"
                                    label="Password"
                                    variant="outlined"
                                    type="password"
                                    :rules="[
                                        (v) => !!v || 'Password is required',
                                        (v) =>
                                            /^[A-Za-z0-9]+$/.test(v) ||
                                            'Password must be alphanumeric',
                                        (v) =>
                                            (v &&
                                                v.length >= 8 &&
                                                v.length <= 12) ||
                                            'Password must be between 8 and 12 characters',
                                        (v) =>
                                            /[A-Z]/.test(v) ||
                                            'Password must contain at least one uppercase letter',
                                    ]"
                                ></v-text-field> -->
                                    <!-- @tanisq need more information of this task -->
                                    <!-- <v-text-field
                                    v-model="form.level"
                                    label="Role"
                                    variant="outlined"
                                    :rules="[
                                        (v) => !!v || 'Role is required',
                                        // only number allowed
                                        (v) =>
                                            /^\d+$/.test(v) ||
                                            'Role must be a number',
                                    ]"
                                ></v-text-field> -->
                                </div>

                                <v-checkbox
                                    v-model="form.enable_2fa"
                                    label="Enable 2FA"
                                    hide-details
                                ></v-checkbox>
                            </div>
                        </v-form>
                    </v-list-item>

                    <v-divider></v-divider>
                    <v-list-subheader class="text-uppercase">
                        <v-icon>mdi-tune</v-icon>
                        Permissions</v-list-subheader
                    >
                    <v-list-item>
                        <v-tabs v-model="tab" bg-color="primary">
                            <!-- {{ hasInvalidPercentages }} -->
                            <v-tab
                                v-for="(tabName, i) in Object.keys(permissions)"
                                :key="`tab-${i}`"
                                :text="`${tabName}`"
                                :value="`tab-${i}`"
                            ></v-tab>
                        </v-tabs>
                        <v-tabs-window v-model="tab">
                            <v-tabs-window-item
                                v-for="(tabName, j) in Object.keys(permissions)"
                                :key="`tab-content-${j}`"
                                :value="`tab-${j}`"
                            >
                                <!-- <v-radio-group
                                v-model="selectedPermissions"
                                inline
                                label="Permissions"
                                hide-details
                            >
                                <v-radio
                                    v-for="permission in permissions"
                                    :key="permission.id"
                                    :label="permission.name"
                                    :value="permission.id"
                                ></v-radio>
                            </v-radio-group> -->
                                <v-data-table
                                    :fixed-header="true"
                                    :fixed-footer="true"
                                    :sticky="true"
                                    height="25vh"
                                    :items="permissions[tabName]"
                                    :headers="[
                                        {
                                            value: 'name',
                                            title: 'Name',
                                            visible: true,
                                            sortable: true,
                                        },
                                        {
                                            value: 'actions',
                                            title: 'Actions',
                                            visible: true,
                                            sortable: true,
                                        },
                                    ]"
                                >
                                    <template
                                        v-slot:[`item.actions`]="{ item }"
                                    >
                                        <v-checkbox-btn
                                            :disabled="true"
                                            v-model="selectedPermissions"
                                            :value="item.id"
                                        ></v-checkbox-btn>
                                    </template>
                                </v-data-table>
                                <!-- <div>
                                {{ permissions[tabName] }}
                            </div> -->
                            </v-tabs-window-item>
                        </v-tabs-window>
                    </v-list-item>
                </v-list>

                <!-- <v-card-text> -->
                <!-- <v-container> -->
                <!-- <v-row> -->

                <!-- </v-row> -->
                <!-- </v-container> -->
                <!-- </v-card-text> -->
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn
                        @click="submitForm"
                        :loading="loading"
                        color="primary"
                    >
                        submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script setup>
import { ref, computed, onMounted, defineProps, nextTick, watch } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
import axios from "axios";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import { useStore } from "vuex";
import {
    // eslint-disable-next-line no-unused-vars
    createUser,
    updateUser,
    deleteUser,
    getPermissions,
    getRoles,
} from "@/components/users/service.js";
const dialog = ref(false);
const pageSize = ref(10);
const page = ref(1);
function handlePageChange(value) {
    page.value = value;
}
function handlePageSizeChange(value) {
    pageSize.value = value;
}
const form = ref({
    name: "",
    email: "",
    level: "",
    password: "",
    enable_2fa: true,
});

const permissions = ref([]);
const tab = ref("tab-0");
const selectedPermissions = ref([]);
const roles = ref([]);

const levelItems = ref([
    { text: "Admin", value: 1 },
    { text: "Support", value: 2 },
    { text: "Publisher Manager", value: 3 },
    { text: "Advertiser Manager", value: 4 },
    { text: "Crm User", value: 5 },
]);

defineProps({
    filterData: {
        type: Object,
        default: () => ({}),
    },
});
const userFormRef = ref(null);
// const store = useStore();
// const apiUrl = computed(() => process.env.VUE_APP_API_URL);
const searchTable = ref("");
const allColumns = ref([
    { field: "id", title: "ID", visible: true, sortable: true },
    { field: "name", title: "Name", visible: true, sortable: true },
    { field: "email", title: "Email", visible: true, sortable: true },
    { field: "level", title: "Level", visible: true, sortable: true },
    { field: "actions", title: "Actions", visible: true },
]);
const rows = ref([]);
const dropdownOpen = ref(false);
const dropdown = ref(null);
// const countries = computed(() => store.getters.getCountries);
const editId = ref(null);
// const getCountryCode = (countryName) => {
//     const country = countries.value.find((c) => c.name === countryName);
//     return country ? country.code.toLowerCase() : "default";
// };
// function toggleDropdown() {
//     dropdownOpen.value = !dropdownOpen.value;
// }
// const editModal = ref(null);
// const createModal = ref(null);
const loading = ref(false);
function newUser() {
    editId.value = null;
    dialog.value = true;
    nextTick(() => {
        userFormRef.value.reset();
        form.value.level = 5;
        form.value.enable_2fa = true;
        // createUser();
    });
}
const submitForm = async () => {
    const { valid } = await userFormRef.value.validate();
    if (!valid) {
        return;
    }
    loading.value = true;
    try {
        // console.log("form", form.value);
        if (editId.value) {
            // delete form.value.password;
            // delete form.value.email;
            await updateUser(form.value);
            // @tanisq what is the success response of this
            await fetchDataWithFilter();
            Swal.fire({
                icon: "success",
                title: "Successfully updated",
                text: "User successfully updated",
            });
        } else {
            await createUser(form.value);
            await fetchDataWithFilter();
            Swal.fire({
                icon: "success",
                title: "Successfully created",
                text: "User successfully created",
            });
        }
    } catch (error) {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: error.message || "Please try again.",
        });
        console.error("Failed to save:", error);
    } finally {
        dialog.value = false;
        loading.value = false;
    }
};
watch(
    () => form.value.level,
    (newVal) => {
        console.log("newVal", newVal);
        if (newVal || newVal === 0) {
            const role = roles.value.find((role) => role.id == newVal);
            selectedPermissions.value = role.permissions;
        }
    },
);
function editLead(item) {
    console.log("editLead item", item);
    editId.value = item.id;
    form.value = item;
    form.value.level = item.level;
    form.value.enable_2fa = item.enable_2fa === "true" ? true : false;
    dialog.value = true;
}
function deleteLeads(id) {
    Swal.fire({
        title: "Are you sure?",
        text: `You are sure about to delete`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                await deleteUser(id);
                await fetchDataWithFilter();
                Swal.fire({
                    icon: "success",
                    title: "Successfully deleted",
                    text: "User successfully deleted",
                });
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.message || "Failed to delete user",
                });
                console.error("Failed to delete:", error);
            }
        }
    });
}
function toggleColumnVisibility(field) {
    const column = allColumns.value.find((col) => col.field === field);
    if (column) {
        column.visible = !column.visible;
    }
}
const visibleColumns = computed(() => {
    return allColumns.value.filter((col) => col.visible);
});
// const filteredRows = computed(() => {
//     return rows.value.filter((row) => {
//         return Object.keys(props.filterData.filters || {}).every((key) => {
//             return (
//                 !props.filterData.filters[key] ||
//                 row[key] === props.filterData.filters[key]
//             );
//         });
//     });
// });
async function fetchDataWithFilter() {
    const url = `/api/users`;
    try {
        const { data } = await axios.get(url);
        rows.value = data;
    } catch (error) {
        console.error("Failed to fetch data:", error);
    }
}

onMounted(async () => {
    fetchDataWithFilter();
    permissions.value = (await getPermissions()) || [];
    roles.value = (await getRoles()) || [];
});
</script>
<style scoped>
.table-container {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
    padding: 10px;
    max-height: 400px;
    overflow: scroll;
}
.dropdown-content label {
    display: block;
    padding: 5px 0;
}
.dropdown-content label:hover {
    background-color: #f1f1f1;
}
.img-flags {
    width: 15px;
    height: 15px;
}
</style>
