<template>
    <div>
        <div class="col-md-12">
            <div class="mb-4 pb-2">
                <nav aria-label="breadcrumb">
                    <ol class="box-shadow breadcrumb bg-light p-4">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'DashboardView' }"
                                >Home</router-link
                            >
                        </li>
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'PublishersView' }"
                                >Publisher</router-link
                            >
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            {{ $route.params.id }}
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Edit Publisher
                        </li>
                    </ol>
                </nav>
            </div>
            <v-tabs show-arrows v-model="tab">
                <v-tab :value="1">Settings</v-tab>
                <v-tab :value="10">Payouts</v-tab>
                <!-- <v-tab :value="2">Revenue</v-tab> -->
                <v-tab :value="3">Distribution</v-tab>
                <v-tab :value="4">Caps</v-tab>
                <v-tab :value="5">Supported Countries</v-tab>
                <!-- <v-tab :value="6">Opening Hours</v-tab> -->
                <!-- <v-tab :value="7">Exclusions</v-tab> -->
                <v-tab :value="8">Override Publisher ID</v-tab>
                <v-tab :value="9">API Offer Slug Overrides</v-tab>
            </v-tabs>
            <v-tabs-window v-model="tab">
                <v-tabs-window-item :value="1">
                    <div class="box-shadow p-4">
                        <v-form
                            ref="settingFormRef"
                            @submit.prevent="submitForm"
                        >
                            <div class="statusDiv">
                                <v-switch
                                    true-value="active"
                                    color="success"
                                    false-value="inactive"
                                    v-model="form.status"
                                    :label="`Publisher is ${form.status}`"
                                ></v-switch>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <!-- {{ form }} -->
                                    <v-text-field
                                        :required="true"
                                        v-model="form.api_token"
                                        label="API Token"
                                        variant="outlined"
                                        readonly
                                    >
                                        <template #append-inner>
                                            <StandardBtn
                                                type="copy"
                                                variant="text"
                                                @click="copyToClipboard"
                                            />
                                        </template>
                                    </v-text-field>
                                    <!-- disabled -->
                                </div>
                                <div class="col-md-6">
                                    <v-text-field
                                        :required="true"
                                        v-model="form.name"
                                        label="Name"
                                        variant="outlined"
                                        :rules="[
                                            (v) => !!v || 'Name is required',
                                        ]"
                                    ></v-text-field>
                                </div>
                                <div class="col-md-6">
                                    <!-- autologin_interval -->
                                    <v-select
                                        label="Auto login interval"
                                        v-model="form.autologin_interval"
                                        variant="outlined"
                                        item-title="text"
                                        item-value="value"
                                        :items="[
                                            {
                                                text: 'Days',
                                                value: 'days',
                                            },
                                            {
                                                text: 'Hours',
                                                value: 'hours',
                                            },
                                            {
                                                text: 'Minutes',
                                                value: 'minutes',
                                            },
                                        ]"
                                        :rules="[
                                            (v) =>
                                                !!v ||
                                                'Auto login interval is required',
                                        ]"
                                    >
                                    </v-select>
                                    <!-- <v-text-field
                                        :required="true"
                                        v-model="form.autologin_interval"
                                        label="autolo gin interval"
                                        variant="outlined"
                                        :rules="[
                                            (v) =>
                                                !!v ||
                                                'Auto login interval is required',
                                        ]"
                                    ></v-text-field> -->
                                </div>
                                <div class="col-md-6">
                                    <!-- offer_url -->
                                    <v-text-field
                                        label="Offer URL"
                                        v-model="form.offer_url"
                                        variant="outlined"
                                    >
                                    </v-text-field>
                                </div>
                                <div class="col-md-6">
                                    <!-- locate_leads_by -->
                                    <v-select
                                        label="Locate leads by"
                                        v-model="form.locate_leads_by"
                                        variant="outlined"
                                        item-title="text"
                                        item-value="value"
                                        :items="[
                                            {
                                                text: 'System',
                                                value: 'system',
                                            },
                                            {
                                                text: 'Phone',
                                                value: 'phone',
                                            },
                                            {
                                                text: 'IP address',
                                                value: 'ip_address',
                                            },
                                        ]"
                                        :rules="[
                                            (v) =>
                                                !!v ||
                                                'Locate leads by is required',
                                        ]"
                                    >
                                    </v-select>
                                </div>
                            </div>
                            <v-btn
                                @click="submitForm"
                                :loading="loading"
                                color="primary"
                            >
                                Submit
                            </v-btn>
                        </v-form>
                    </div>
                </v-tabs-window-item>
                <!-- <v-tabs-window-item :value="2">
                    <div class="box-shadow p-4">
                        <RevenueComponent
                            @reloadApi="loadPublisher"
                            :revenue="affiliateData.revenues"
                        />
                    </div>
                </v-tabs-window-item> -->
                <v-tabs-window-item :value="3">
                    <div class="box-shadow p-4">
                        <DistributionComponent
                            @reloadApi="loadPublisher"
                            type="publisher"
                            :id="$route.params.id"
                        />
                    </div>
                </v-tabs-window-item>
                <v-tabs-window-item :value="4">
                    <div class="box-shadow p-4">
                        <CapsComponentComponent
                            @reloadApi="loadPublisher"
                            :cap="affiliateData.caps"
                        />
                    </div>
                </v-tabs-window-item>
                <v-tabs-window-item :value="5">
                    <div class="box-shadow p-4">
                        <SupportedCountriesComponent
                            @reloadApi="loadPublisher"
                            :country="affiliateData.supported_countries"
                        />
                    </div>
                </v-tabs-window-item>
                <!-- <v-tabs-window-item :value="6">
                    <div class="box-shadow p-4">
                        <OpenHourComponent
                            :openingHour="affiliateData.openingHours"
                        />
                    </div>
                </v-tabs-window-item> -->
                <!-- <v-tabs-window-item :value="7">
                    <div class="box-shadow p-4">
                        <ExclusionsComponent
                            @reloadApi="loadPublisher"
                            :exclusion="affiliateData.exclusions"
                        />
                    </div>
                </v-tabs-window-item> -->
                <v-tabs-window-item :value="8">
                    <div class="box-shadow p-4">
                        <overrideComponent
                            @reloadApi="loadPublisher"
                            :affiliateName="form.name"
                            :override="affiliateData.publisher_overrides"
                        />
                    </div>
                </v-tabs-window-item>
                <v-tabs-window-item :value="9">
                    <div class="box-shadow p-4">
                        <apiOfferSlugOverrideComponent
                            @reloadApi="loadPublisher"
                            :affiliateName="form.name"
                            :apiOfferOverRider="affiliateData.funnel_overrides"
                        />
                    </div>
                </v-tabs-window-item>
                <v-tabs-window-item :value="10">
                    <div class="box-shadow p-4">
                        <!-- {{ affiliateData.affiliates.payouts[0] }} -->
                        <PayoutComponent
                            @reloadApi="loadPublisher"
                            :payout="affiliateData.payouts"
                        />
                    </div>
                </v-tabs-window-item>
            </v-tabs-window>
        </div>
    </div>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import { onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2";
import {
    getPublishersById,
    updatePublishers,
} from "@/components/publishers/service";
import PayoutComponent from "./payouts/DataTableComponent.vue";
import overrideComponent from "./overrideAffiliate/overrideComponent.vue";
import ExclusionsComponent from "./exclusions/ExclusionsComponent.vue";
// import RevenueComponent from "./revenue/RevenueComponent.vue";
import DistributionComponent from "../distributions/DataTableComponent.vue";
import CapsComponentComponent from "./caps/DataTableComponent.vue";
import SupportedCountriesComponent from "./supportedcountries/DataTableComponent.vue";
import OpenHourComponent from "./openinghours/DataTableComponent.vue";
import apiOfferSlugOverrideComponent from "./offerSlugOverride/offerSlugOverrideComponent.vue";
import { useRoute } from "vue-router";
const Route = useRoute();
const form = reactive({
    name: "",
    status: "active",
    autologin_interval: "days",
    locate_leads_by: "system",
    api_token: null,
    tracking_domain: null,
    offer_url: null,
});
const tab = ref(1);
const affiliateData = ref([]);
const loadPublisher = async () => {
    const data = await getPublishersById(Route.params.id);
    console.log(
        "🚀 ~ file: EditComponent.vue:175 ~ loadPublisher ~ data:",
        data.affiliates,
    );
    affiliateData.value = await data;
    form.name = await data.affiliates.name;
    form.status = await data.affiliates.status;
    form.api_token = await data.affiliates.api_token;
    form.autologin_interval = await data.affiliates.autologin_interval;
    form.locate_leads_by = await data.affiliates.locate_leads_by;
    form.tracking_domain = await data.affiliates.tracking_domain;
    form.offer_url = await data.affiliates.offer_url;
};
const copyToClipboard = () => {
    navigator.clipboard.writeText(form.api_token);
};
onMounted(async () => {
    await loadPublisher();
});
const loading = ref(false);
const settingFormRef = ref(null);
const submitForm = async () => {
    loading.value = true;
    const { valid } = await settingFormRef.value.validate();
    if (valid) {
        form.id = Route.params.id;
        const resp = await updatePublishers(form);
        if (!resp) {
            Swal.fire({
                title: "Error",
                text: "Something went wrong",
                icon: "error",
                confirmButtonText: "Ok",
            });
            loading.value = false;
            return;
        }
        Swal.fire({
            title: "Success",
            text: "Advertiser updated successfully",
            icon: "success",
            confirmButtonText: "Ok",
        });
        loading.value = false;
    }
};
</script>
<style>
.statusDiv {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
}
</style>
