<template>
    <div>
        <div class="col-md-12">
            <div class="mb-4 pb-2">
                <nav aria-label="breadcrumb">
                    <ol class="box-shadow breadcrumb bg-light p-4">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'DashboardView' }"
                                >Home</router-link
                            >
                        </li>
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'AdvertisersView' }"
                                >Advertisers</router-link
                            >
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            {{ $route.params.id }}
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Edit Advertiser
                        </li>
                    </ol>
                </nav>
            </div>
            <v-tabs show-arrows v-model="tab">
                <v-tab :value="1">Settings</v-tab>
                <v-tab :value="2">Revenue</v-tab>
                <v-tab :value="3">Distribution</v-tab>
                <v-tab :value="4">Caps</v-tab>
                <v-tab :value="5">Supported Countries</v-tab>
                <v-tab :value="6">Opening Hours</v-tab>
                <v-tab :value="7">Exclusions</v-tab>
                <v-tab :value="8">Override Publisher ID</v-tab>
                <v-tab :value="9">API Offer Slug Overrides</v-tab>
            </v-tabs>
            <v-tabs-window v-model="tab">
                <v-tabs-window-item :value="1">
                    <!-- Form for Tab 1 (Settings) -->
                    <div class="box-shadow p-4">
                        <v-form
                            ref="settingFormRef"
                            @submit.prevent="submitForm"
                        >
                            <div class="statusDiv">
                                <v-switch
                                    true-value="active"
                                    color="success"
                                    false-value="inactive"
                                    v-model="form.status"
                                    :label="`${form.status}`"
                                ></v-switch>
                            </div>
                            <div class="statusDiv">
                                <v-switch
                                    true-value="active"
                                    color="success"
                                    false-value="inactive"
                                    v-model="form.is_test_advertiser"
                                    :label="`Is Test`"
                                ></v-switch>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <v-text-field
                                        :required="true"
                                        v-model="form.name"
                                        label="Name"
                                        variant="outlined"
                                        :rules="[
                                            (v) => !!v || 'Name is required',
                                        ]"
                                    ></v-text-field>
                                </div>
                                <div class="col-md-6">
                                    <v-text-field
                                        :required="true"
                                        v-model="form.display_name"
                                        label="Display Name"
                                        variant="outlined"
                                        :rules="[
                                            (v) =>
                                                !!v ||
                                                'Display Name is required',
                                        ]"
                                    ></v-text-field>
                                </div>
                                <v-col cols="12">
                                    <v-autocomplete
                                        label="Manager"
                                        v-model="form.user_id"
                                        :items="users"
                                        placeholder="Select a user"
                                        item-title="name"
                                        item-value="id"
                                        variant="outlined"
                                        hide-details
                                        clearable
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </div>
                            <v-btn
                                @click="submitForm"
                                :loading="loading"
                                color="primary"
                            >
                                Submit
                            </v-btn>
                        </v-form>
                    </div>
                </v-tabs-window-item>
                <v-tabs-window-item :value="2">
                    <div class="box-shadow p-4">
                        <RevenueComponent
                            @reloadApi="loadAdvertiser"
                            :revenue="advertiserData.revenues"
                        />
                    </div>
                </v-tabs-window-item>
                <v-tabs-window-item :value="3">
                    <div class="box-shadow p-4">
                        <DistributionComponent
                            type="advertiser"
                            @reloadApi="loadAdvertiser"
                            :id="$route.params.id"
                        />
                    </div>
                </v-tabs-window-item>
                <v-tabs-window-item :value="4">
                    <div class="box-shadow p-4">
                        <CapsComponentComponent
                            @reloadApi="loadAdvertiser"
                            :cap="advertiserData.caps"
                        />
                    </div>
                </v-tabs-window-item>
                <v-tabs-window-item :value="5">
                    <div class="box-shadow p-4">
                        <SupportedCountriesComponent
                            @reloadApi="loadAdvertiser"
                            :country="advertiserData.supported_countries"
                        />
                    </div>
                </v-tabs-window-item>
                <v-tabs-window-item :value="6">
                    <div class="box-shadow p-4">
                        <!-- {{advertiserData.openingHours}} -->
                        <OpenHourComponent
                            @reloadApi="loadAdvertiser"
                            :openingHour="advertiserData.openingHours"
                        />
                    </div>
                </v-tabs-window-item>
                <v-tabs-window-item :value="7">
                    <div class="box-shadow p-4">
                        <ExclusionsComponent
                            @reloadApi="loadAdvertiser"
                            :exclusion="advertiserData.exclusions"
                        />
                    </div>
                </v-tabs-window-item>
                <v-tabs-window-item :value="8">
                    <div class="box-shadow p-4">
                        <overrideComponent
                            @reloadApi="loadAdvertiser"
                            :advertiserName="form.name"
                            :override="advertiserData.publisher_overrides"
                        />
                    </div>
                </v-tabs-window-item>
                <v-tabs-window-item :value="9">
                    <div class="box-shadow p-4">
                        <apiOfferSlugOverrideComponent
                            @reloadApi="loadAdvertiser"
                            :advertiserName="form.name"
                            :apiOfferOverRider="advertiserData.funnel_overrides"
                        />
                    </div>
                </v-tabs-window-item>
            </v-tabs-window>
        </div>
    </div>
</template>
<script setup>
import { onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2";
import {
    getAdvertiserById,
    updateAdvertiser,
    fetchUsersRequest,
} from "@/components/advertisers/service";
import overrideComponent from "./overrideAffiliate/overrideComponent.vue";
import ExclusionsComponent from "./exclusions/ExclusionsComponent.vue";
import RevenueComponent from "./revenue/RevenueComponent.vue";
import DistributionComponent from "../distributions/DataTableComponent.vue";
import CapsComponentComponent from "./caps/DataTableComponent.vue";
import SupportedCountriesComponent from "./supportedcountries/DataTableComponent.vue";
import OpenHourComponent from "./openinghours/DataTableComponent.vue";
import apiOfferSlugOverrideComponent from "./offerSlugOverride/offerSlugOverrideComponent.vue";
import { useRoute } from "vue-router";
const Route = useRoute();
const form = reactive({
    display_name: "",
    name: "",
    status: "active",
    user_id: null,
    is_test_advertiser: "inactive",
});
const users = ref([]);
const tab = ref(1);
const advertiserData = ref([]);
const loadAdvertiser = async () => {
    const data = await getAdvertiserById(Route.params.id);
    advertiserData.value = await data;
    // console.log(data.advertiser);

    form.name = data.advertiser.name;
    form.display_name = data.advertiser.display_name;
    form.is_test_advertiser = data.advertiser.is_test_advertiser;
    form.status = data.advertiser.status;
    form.user_id = data.advertiser?.user_id || null;
};
onMounted(async () => {
    await loadAdvertiser();
    users.value = await fetchUsersRequest();
});
const loading = ref(false);
const settingFormRef = ref(null);
const submitForm = async () => {
    loading.value = true;
    const { valid } = await settingFormRef.value.validate();
    if (valid) {
        form.id = Route.params.id;
        const resp = await updateAdvertiser(form);
        if (!resp) {
            Swal.fire({
                title: "Error",
                text: "Something went wrong",
                icon: "error",
                confirmButtonText: "Ok",
            });
            loading.value = false;
            return;
        }
        Swal.fire({
            title: "Success",
            text: "Advertiser updated successfully",
            icon: "success",
            confirmButtonText: "Ok",
        });
        loading.value = false;
    }
};
</script>
<style>
.statusDiv {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
}
</style>
