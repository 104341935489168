<template>
    <div class="card shadow-none">
        <div
            class="d-flex justify-content-between align-items-center p-3 flex-wrap"
        >
            <div class="custom-label">FILTERS</div>
            <div class="d-flex gap-2">
                <v-btn
                    color="#c8c8c8"
                    size="small"
                    class="text-none"
                    @click="resetFilters"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="indigo-darken-2"
                    class="text-none"
                    @click="applyFilters"
                    size="small"
                >
                    Apply
                </v-btn>
            </div>
        </div>
        <hr class="m-0" />
        <div class="p-2">
            <div class="d-flex gap-2 mb-2 flex-wrap">
                <DateRangeCom
                    class="w-full"
                    v-model.date="dateRange"
                ></DateRangeCom>
                <!-- <div class="flex-grow-1">
                    <div class="custom-label mb-1">Start Date</div>
                    <input
                        type="date"
                        id="startDate"
                        class="form-control"
                        v-model="startDate"
                    />
                </div>
                <div class="flex-grow-1">
                    <div class="custom-label mb-1">End Date</div>
                    <input
                        type="date"
                        id="endDate"
                        class="form-control"
                        v-model="endDate"
                    />
                </div> -->
            </div>
            <div>
                <v-switch
                    v-model="last24Hours"
                    label="Last 24 hours"
                    density="compact"
                    hide-details
                    color="indigo-darken-2"
                    class="custom-switch"
                ></v-switch>
                <v-switch
                    v-model="insideSelectedHourRange"
                    label="Inside selected hour range only"
                    density="compact"
                    hide-details
                    color="indigo-darken-2"
                    class="custom-switch"
                ></v-switch>
                <v-switch
                    v-model="createdInsideDateRange"
                    label="Created inside date range only"
                    density="compact"
                    hide-details
                    color="indigo-darken-2"
                    class="custom-switch"
                ></v-switch>

                <v-switch
                    v-model="compareWithPast"
                    label="Compare with past"
                    density="compact"
                    hide-details
                    color="indigo-darken-2"
                    class="custom-switch"
                ></v-switch>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Publisher</div>
                <v-autocomplete
                    v-model="selectedPublishers"
                    :items="publishers"
                    placeholder="Select publishers"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item, index }">
                        <span>
                            <v-chip
                                v-if="index === 0"
                                small
                                style="margin-left: 0px"
                            >
                                <span>{{ item.title }}</span>
                            </v-chip>
                            <v-chip
                                v-if="index === 1 && selectedPublishers"
                                small
                                class="text-caption"
                                style="font-size: 11px !important"
                            >
                                +{{ selectedPublishers.length - 1 }}
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters.publishers"
                        :label="
                            filters.publishers
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="filters.publishers ? 'danger' : 'success'"
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Countries</div>
                <v-autocomplete
                    v-model="selectedCountries"
                    :items="countries"
                    item-title="name"
                    item-value="id"
                    placeholder="Select countries"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item, index }">
                        <span>
                            <v-chip
                                v-if="index === 0"
                                small
                                style="margin-left: 0px"
                            >
                                <span>{{ item.title }}</span>
                            </v-chip>
                            <v-chip
                                v-if="index === 1 && selectedCountries"
                                small
                                class="text-caption"
                                style="font-size: 11px !important"
                            >
                                +{{ selectedCountries.length - 1 }}
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters.countries"
                        :label="
                            filters.countries
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="filters.countries ? 'danger' : 'success'"
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Advertisers</div>
                <v-autocomplete
                    v-model="selectedAdvertisers"
                    :items="advertisers"
                    item-title="name"
                    item-value="id"
                    placeholder="Select advertisers"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item, index }">
                        <span>
                            <v-chip
                                v-if="index === 0"
                                small
                                style="margin-left: 0px"
                            >
                                <span>{{ item.title }}</span>
                            </v-chip>
                            <v-chip
                                v-if="index === 1 && selectedAdvertisers"
                                small
                                class="text-caption"
                                style="font-size: 11px !important"
                            >
                                +{{ selectedAdvertisers.length - 1 }}
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters.advertisers"
                        :label="
                            filters.advertisers
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="filters.advertisers ? 'danger' : 'success'"
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
            <div class="mt-3">
                <div class="custom-label mb-2">Statuses</div>
                <v-autocomplete
                    v-model="selectedStatuses"
                    :items="statuses"
                    placeholder="Select statuses"
                    item-title="brand_status"
                    item-value="brand_status"
                    variant="outlined"
                    density="compact"
                    multiple
                    hide-details
                    clearable
                >
                    <template v-slot:selection="{ item, index }">
                        <span>
                            <v-chip
                                v-if="index === 0"
                                density="compact"
                                style="margin-left: 0px"
                            >
                                <span>{{ item.title }}</span>
                            </v-chip>
                            <v-chip
                                v-if="index === 1 && selectedStatuses"
                                density="compact"
                                class="text-caption"
                                style="font-size: 11px !important"
                            >
                                +{{ selectedStatuses.length - 1 }}
                            </v-chip>
                        </span>
                    </template>
                </v-autocomplete>
                <div class="d-flex justify-end gap-2">
                    <v-switch
                        v-model="filters.statuses"
                        :label="
                            filters.statuses
                                ? 'exclude selection'
                                : 'include selection'
                        "
                        density="compact"
                        hide-details
                        :color="filters.statuses ? 'danger' : 'success'"
                        class="custom-switch"
                    ></v-switch>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchFilterData } from "./service";
import DateRangeCom from "@/components/global/dateRangeCom.vue";
import { startOfToday, format } from "date-fns";
export default {
    components: {
        DateRangeCom,
    },
    data() {
        return {
            menu: false,
            dateRange: [],
            startDate: "",
            endDate: "",
            last24Hours: false,
            insideSelectedHourRange: false,
            createdInsideDateRange: false,
            compareWithPast: false,

            // list of selected value here
            selectedPublishers: [],
            selectedCountries: [],
            selectedAdvertisers: [],
            selectedStatuses: [],
            // list value here
            publishers: [],
            countries: [],
            advertisers: [],
            statuses: [],
            filters: {
                publishers: false,
                countries: false,
                advertisers: false,
                statuses: false,
            },
        };
    },
    watch: {
        dateRange: function (newVal) {
            console.log("🚀 ~ file: FilterComponent.vue:447 ~ newVal:", newVal);
            if (newVal) {
                this.startDate = newVal[0]?.split(" ")[0];
                this.endDate = newVal[1]?.split(" ")[0];
                this.startTime = newVal[0]?.split(" ")[1];
                this.endTime = newVal[1]?.split(" ")[1];
            }
        },
    },

    async mounted() {
        this.dateRange = [
            format(startOfToday(new Date()), "yyyy-MM-dd HH:mm"),
            format(new Date(), "yyyy-MM-dd HH:mm"),
        ];
        await this.fetchFiltersValues();
        this.applyFilters();
    },
    methods: {
        async fetchFiltersValues() {
            const response = await fetchFilterData();
            if (!response) return;
            this.advertisers = response?.advertisers || [];
            this.countries = response?.countries || [];
            this.statuses = response?.statuses || [];
            this.publishers = response?.publishers || [];
            let yourDate = new Date();
            this.startDate = yourDate.toISOString().split("T")[0];
            this.endDate = yourDate.toISOString().split("T")[0];

            // this.selectedCountries = response?.countries?.map(c => c.id) || [];
            // this.selectedPublishers = response?.publishers?.map(p => p.id) || [];
            // this.selectedAdvertisers = response?.advertisers?.map(a => a.id) || [];
            // this.selectedStatuses = response?.statuses?.map(s => s.brand_status) || [];
        },
        resetFilters() {
            this.last24Hours = false;
            this.insideSelectedHourRange = false;
            this.createdInsideDateRange = false;
            this.compareWithPast = false;
            this.selectedPublishers = [];
            this.selectedCountries = [];
            this.selectedAdvertisers = [];
            this.selectedStatuses = [];
            for (const key in this.filters) {
                this.filters[key] = false;
            }
        },
        applyFilters() {
            const payload = {
                // last24Hours: this.last24Hours,
                // insideSelectedHourRange: this.insideSelectedHourRange,
                // createdInsideDateRange: this.createdInsideDateRange,
                // compareWithPast: this.compareWithPast,

                start_date: this.startDate || null,
                end_date: this.endDate || null,
                country_id: this.selectedCountries?.length
                    ? this.selectedCountries
                    : null,
                affiliate_id: this.selectedPublishers?.length
                    ? this.selectedPublishers
                    : null,
                advertiser_id: this.selectedAdvertisers?.length
                    ? this.selectedAdvertisers
                    : null,
                brand_status: this.selectedStatuses?.length
                    ? this.selectedStatuses
                    : null,
                excludes: Object.keys(this.filters).filter(
                    (key) => this.filters[key],
                ),
            };
            this.$emit("applyFilters", payload);
        },
    },
};
</script>

<style></style>

<style scoped>
.custom-label {
    font-weight: 500;
    font-size: 13px;
    color: #212121;
}
.custom-switch {
    padding-left: 10px;
}
.custom-switch >>> .v-label {
    font-size: 12px !important;
    font-weight: 600;
    line-height: 14px;
}
.custom-switch >>> .v-switch__track {
    height: 20px;
    min-width: 40px;
    border: 1px solid #989898;
    background-color: rgb(219 219 219);
}
.custom-switch >>> .v-switch__thumb {
    box-shadow: none !important;
    background-color: #a5a5a5;
    height: 14px;
    width: 14px;
}
</style>
